@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Roboto:100,300,400,500,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
@import url(https://fonts.googleapis.com/css?family=Open+Sans:300,400,600,700);
/*body {*/
/*  margin: 0;*/
/*  padding: 0;*/
/*  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",*/
/*    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",*/
/*    sans-serif;*/
/*  -webkit-font-smoothing: antialiased;*/
/*  -moz-osx-font-smoothing: grayscale;*/
/*}*/

/*code {*/
/*  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",*/
/*    monospace;*/
/*}*/
body{
    margin: 0;
}
.footer-main{
    /*position: absolute;*/
    height: 77px;
    width: 100%;
    /*background: #25345c;*/
    bottom: 0;
}
.border-bottom{
border-bottom: 1px solid #E2E5ED !important;
}

.main-form {
  padding: 0 30px; }

.check-forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px; }
  .check-forgot .links {
    margin-top: 15px;
    color: #25345c; }
  .check-forgot .MuiCheckbox-colorPrimary-64.MuiCheckbox-checked-61 {
    color: #25345c; }

.status-para {
  padding-left: 24px;
  padding-right: 24px;
  color: #3e3f42;
  font-size: 30px;
  line-height: 36px;
  letter-spacing: 1px;
  font-family: "Roboto", sans-serif;
  font-weight: 600;
  text-align: center; }
  .status-para .green {
    color: green; }
  .status-para .red {
    color: red; }

.loader-wrapper {
  width: 100vw;
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(255, 255, 255, 0.5);
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  z-index: 1; }

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 94px;
  height: 94px; }

.lds-ripple div {
  position: absolute;
  border: 5px solid #25345C;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite; }

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s; }

@keyframes lds-ripple {
  0% {
    top: 42px;
    left: 42px;
    width: 0;
    height: 0;
    opacity: 1; }
  100% {
    top: -1px;
    left: -1px;
    width: 88px;
    height: 88px;
    opacity: 0; } }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.service-category-text {
  font-weight: bolder;
  font-size: 20px; }

.appt-table {
  max-width: 700px;
  margin: 20px 0;
  width: 100%; }
  .appt-table .schedule-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; }
    .appt-table .schedule-row .del-svc-btn {
      float: right;
      background-color: #ac0100 !important;
      border-radius: 15px !important;
      width: 100px;
      height: 40px;
      color: white !important;
      font-size: 15px;
      margin: 0px 10px !important;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif; }
      .appt-table .schedule-row .del-svc-btn:hover {
        background-color: red !important;
        color: white; }
  .appt-table .schedule-left {
    text-align: left; }
    .appt-table .schedule-left .main-head-text {
      text-align: left; }
  .appt-table .schedule-right {
    display: flex; }

.no-result {
  display: flex;
  justify-content: center;
  margin-top: 30px; }
  .no-result .no-result-text {
    color: #3e3f42;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px; }

.MuiGrid-container {
  margin: 0 !important;
  width: 100% !important; }

.service-type-chip {
  margin: 2px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

body {
  font-family: "Open Sans", sans-serif; }

.main-admin-container .admin-side-nav .menu-box .menu-head, .main-admin-container .admin-side-nav .support-box .menu-head {
  font-style: normal;
  font-weight: 500;
  font-size: 12px;
  line-height: 18px;
  text-transform: uppercase;
  color: #9ea0a5; }

.main-admin-container {
  display: flex;
  width: 100%; }
  .main-admin-container .admin-side-nav {
    font-family: "Roboto", sans-serif;
    background-color: #25345c;
    display: flex;
    flex-direction: column;
    min-height: 610px;
    width: 300px; }
    .main-admin-container .admin-side-nav .logo {
      height: 70px;
      display: flex;
      align-items: center;
      justify-content: center; }
      .main-admin-container .admin-side-nav .logo span {
        font-family: "BrandonGrotesque",sans-serif;
        font-style: normal;
        font-size: 30px;
        color: white; }
    .main-admin-container .admin-side-nav .menu {
      flex: 1 1; }
    .main-admin-container .admin-side-nav .menu-box {
      padding: 15px; }
      .main-admin-container .admin-side-nav .menu-box .menu-head {
        padding-left: 16px; }
      .main-admin-container .admin-side-nav .menu-box .menu-list {
        color: #ffffff; }
        .main-admin-container .admin-side-nav .menu-box .menu-list .MuiListItem-button {
          border-radius: 4px;
          color: #ffffff;
          padding: 8px 16px; }
          .main-admin-container .admin-side-nav .menu-box .menu-list .MuiListItem-button .MuiTypography-subheading {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff; }
          .main-admin-container .admin-side-nav .menu-box .menu-list .MuiListItem-button .badge-icon {
            width: 22px;
            height: 22px;
            background: #fff;
            display: flex;
            justify-content: center;
            border-radius: 15px;
            font-size: 12px;
            align-items: center;
            color: #252529;
            font-weight: 500; }
          .main-admin-container .admin-side-nav .menu-box .menu-list .MuiListItem-button:hover {
            background: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%); }
          .main-admin-container .admin-side-nav .menu-box .menu-list .MuiListItem-button.menu-selected {
            background: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%); }
    .main-admin-container .admin-side-nav .support-box {
      padding: 15px;
      color: white; }
      .main-admin-container .admin-side-nav .support-box::before {
        height: 1px;
        content: "";
        background-color: white;
        width: 95%;
        display: block; }
      .main-admin-container .admin-side-nav .support-box .menu-head {
        padding-left: 16px; }
      .main-admin-container .admin-side-nav .support-box .menu-list .links {
        text-decoration: none; }
        .main-admin-container .admin-side-nav .support-box .menu-list .links :not:hover {
          background-color: #25345c; }
      .main-admin-container .admin-side-nav .support-box .menu-list .MuiListItem-button {
        border-radius: 4px;
        padding: 8px 16px; }
        .main-admin-container .admin-side-nav .support-box .menu-list .MuiListItem-button .MuiTypography-subheading {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 400;
          font-size: 14px;
          line-height: 22px;
          color: #ffffff; }
        .main-admin-container .admin-side-nav .support-box .menu-list .MuiListItem-button:hover {
          background: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%); }
        .main-admin-container .admin-side-nav .support-box .menu-list .MuiListItem-button.menu-selected {
          background: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%); }
    .main-admin-container .admin-side-nav .user-box::after {
      height: 1px;
      content: "";
      background-color: #1d2640;
      width: 100%;
      display: block;
      margin: 0 auto; }
    .main-admin-container .admin-side-nav .user-box .user-list {
      padding-bottom: 0 !important;
      color: white; }
      .main-admin-container .admin-side-nav .user-box .user-list .avatar-img {
        height: 38px;
        width: 38px; }
      .main-admin-container .admin-side-nav .user-box .user-list .MuiListItemText-primary {
        margin-left: 20px;
        color: white; }
      .main-admin-container .admin-side-nav .user-box .user-list .MuiTypography-subheading {
        font-size: 14px;
        color: #ffffff; }
      .main-admin-container .admin-side-nav .user-box .user-list .MuiTypography-colorTextSecondary {
        font-size: 12px;
        color: #9ea0a5;
        margin-left: 20px; }
    .main-admin-container .admin-side-nav .user-box .MuiListItemSecondaryAction-root {
      top: 60%;
      right: 15px; }
  .main-admin-container .main-body {
    display: flex;
    flex-direction: column;
    width: 100%; }
    .main-admin-container .main-body .admin-header {
      background-color: #fff;
      border-bottom: 1px solid #E6E9EF;
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: space-between;
      padding: 0 25px; }
      .main-admin-container .main-body .admin-header .header-text {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #3e3f42; }
      .main-admin-container .main-body .admin-header .add-btn {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 22px;
        color: #ffffff;
        text-transform: capitalize;
        background-color: #25345c;
        padding: 8px 20px; }
        .main-admin-container .main-body .admin-header .add-btn svg {
          margin-left: 10px; }
    .main-admin-container .main-body .body-content .right-top-main {
      background-color: #fff;
      border-bottom: 1px solid #E6E9EF;
      display: flex;
      height: 70px;
      align-items: center;
      justify-content: space-between;
      padding: 0 15px 0 25px; }
      .main-admin-container .main-body .body-content .right-top-main .msg-heading span {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 28px;
        color: #3e3f42; }
      .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list {
        display: flex;
        padding: 0; }
        .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .refresh-btn {
          border: 1px #eaedf3 solid;
          color: #ffffff;
          padding: 8px 20px; }
          .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .refresh-btn span {
            padding-left: 5px;
            color: grey;
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            text-transform: capitalize; }
        .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .new-msg-btn {
          background-color: #25345c;
          padding: 8px 20px; }
          .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .new-msg-btn span {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #ffffff;
            text-transform: capitalize; }
        .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .saved-filters-btn {
          margin-right: 20px; }
          .main-admin-container .main-body .body-content .right-top-main .options-right .horizontal-list .saved-filters-btn span {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 22px;
            color: #3e3f42; }
    .main-admin-container .main-body .body-content .right-side-main {
      display: flex;
      height: calc(100vh - 75px); }
      .main-admin-container .main-body .body-content .right-side-main .left-main {
        background-color: white;
        background-color: white;
        border-right: 1px #E6E9EF solid;
        border-left: 1px #E6E9EF solid;
        display: flex;
        flex-direction: column;
        max-width: 370px; }
        .main-admin-container .main-body .body-content .right-side-main .left-main .search-main {
          padding: 14px 20px;
          border-bottom: 0.5px #E6E9EF solid;
          display: flex;
          flex-direction: row; }
          .main-admin-container .main-body .body-content .right-side-main .left-main .search-main .search-box {
            width: 500px;
            padding: 0 0 0 30px;
            border: 0.5px #E2E5ED solid;
            border-radius: 5px; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .search-main .search-box .search-text {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              color: #9ea0a5;
              width: 80%; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .search-main .search-box button.MuiButtonBase-root.MuiIconButton-root.search-icon {
              color: #9ea0a5; }
        .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main {
          width: 320px;
          height: 100%; }
          .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .unread-heading {
            padding: 20px 30px;
            border-bottom: 0.5px #E6E9EF solid; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .unread-heading span {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              text-transform: uppercase;
              color: #25345c; }
          .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .recent-heading {
            padding: 20px 30px;
            border-bottom: 0.5px #E6E9EF solid; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .recent-heading span {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 500;
              font-size: 12px;
              line-height: 18px;
              text-transform: uppercase;
              color: #25345c; }
          .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs {
            height: 100%; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs span {
              font-size: 14px; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .no-msg {
              display: flex;
              justify-content: center;
              height: 300px;
              align-items: center;
              flex-direction: column; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .no-msg span {
                text-align: center;
                font-size: 16px;
                font-weight: 100;
                color: #25345c;
                opacity: 0.6; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div {
              padding: 20px 30px;
              border-left: 3px white solid; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .dot-icon-unread {
                display: none; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div:hover {
                background: #eaedf3;
                border-left: 3px blue solid; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.active {
                background: #eaedf3;
                border-left: 3px blue solid; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .avatar-img {
                height: 38px;
                width: 38px;
                border: 2px #4facfe solid; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .MuiListItemText-root {
                padding: 0 0 0 15px !important; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .avatar-text {
                width: 125px;
                word-break: break-word; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .avatar-text .name-des .name {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: 500;
                  font-size: 16px;
                  line-height: 24px;
                  color: #3e3f42;
                  margin-right: 8px; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .avatar-text .name-des .designation {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  margin-right: 0px;
                  color: #6b6c6f;
                  word-break: break-word;
                  display: block;
                  width: 125px;
                  overflow: hidden;
                  white-space: nowrap;
                  text-overflow: ellipsis; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .avatar-text .dot-icon {
                  align-self: center;
                  height: 6px;
                  width: 6px;
                  background-color: red;
                  border-radius: 50%;
                  display: inline-block; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .dot-status {
                display: flex;
                flex-direction: column;
                margin-top: 8px;
                margin-right: 16px; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .time-badge {
                flex-direction: column;
                display: flex;
                align-items: center; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .time-badge .time {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  color: #9ea0a5;
                  line-height: 25px; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div .time-badge .badge-icon {
                  align-self: center;
                  width: 20px;
                  height: 20px;
                  background-color: #e6492d;
                  display: flex;
                  justify-content: center;
                  border-radius: 15px;
                  font-size: 12px;
                  align-items: center;
                  color: #fff;
                  font-weight: 500; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div hr.MuiDivider-root-63.MuiDivider-inset-65 {
                margin-left: 100px; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.list-div-unread {
                background-color: #eaedf3;
                padding: 20px 15px; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.list-div-unread .avatar-text {
                  width: 114px; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.list-div-unread:hover {
                  background: #eaedf3;
                  border-left: 3px transparent solid; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.list-div-unread.active {
                  background: #eaedf3;
                  border-left: 3px transparent solid; }
                .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .list-div.list-div-unread .dot-icon-unread {
                  align-self: flex-start;
                  height: 8px;
                  width: 8px;
                  background-color: #25345c;
                  border-radius: 50%;
                  margin-right: 8px;
                  display: inline-block;
                  margin-top: 20px; }
            .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .recent-msg-links {
              display: flex;
              padding: 10px;
              margin-left: 100px; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .recent-msg-links .file-des-txt {
                font-family: "Roboto", sans-serif;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 22px;
                color: #6b6c6f;
                padding-left: 10px;
                margin-top: 5px; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .recent-msg-links .des-div .des-icon {
                color: #9ea0a5; }
              .main-admin-container .main-body .body-content .right-side-main .left-main .left-chatlist-main .left-msgs .recent-msg-links .launch-div {
                padding-left: 50px; }
      .main-admin-container .main-body .body-content .right-side-main .right-main {
        width: 99%;
        display: flex;
        flex-direction: column;
        background-color: #f2f2f2; }
        .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main {
          display: flex;
          background-color: white;
          padding: 15px 30px;
          align-items: center;
          justify-content: space-between; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .avatar-div .avatar-img {
            height: 38px;
            width: 38px;
            border: 2px #4facfe solid; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .avatar-div .name {
            font-family: "Roboto", sans-serif;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 28px;
            color: #3e3f42;
            margin-right: 8px; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .menu-list .video-btn {
            background-color: transparent;
            border-color: transparent;
            cursor: pointer;
            min-width: 50px !important; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .menu-list .video-btn img {
              margin-bottom: 8px; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .menu-list .assigned-btn {
            margin-left: 20px;
            margin-top: -10px;
            background-color: #25345c; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .menu-list .assigned-btn span {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              color: #ffffff;
              text-transform: capitalize; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avatar-menu-list-main .menu-list .icons {
            color: grey;
            margin-left: 25px; }
        .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main {
          background-color: white;
          padding: 10px 25px;
          display: flex;
          border-top: 0.5px #E6E9EF solid;
          border-bottom: 0.5px #E6E9EF solid; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .average {
            display: flex;
            border: 2px #6aa7ee solid;
            padding: 16px 14px 0 11px;
            width: 50%;
            margin-right: 30px; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .average .txt {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              color: #25345c; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .average .time {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: 300;
              font-size: 20px;
              padding-left: 30px;
              color: #25345c; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .content-search-box {
            display: flex;
            width: 50%;
            border: 0.5px #E2E5ED solid;
            border-radius: 5px;
            padding: 5px 0 5px 15px; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .content-search-box .content-lookup {
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              color: #25345c;
              mix-blend-mode: normal;
              opacity: 0.5;
              width: 80%; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .avg-cont-main .content-search-box button.MuiButtonBase-root.MuiIconButton-root.search-icon {
              color: #9ea0a5; }
        .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main {
          height: calc(99vh - 219px);
          border-top: 0.5px #E6E9EF solid;
          border-bottom: 0.5px #E6E9EF solid;
          background-color: #f2f2f2;
          padding: 5px 0; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main {
            width: 100%;
            display: flex;
            justify-content: flex-start; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .typ-icon {
              display: flex;
              flex-direction: column;
              justify-content: center;
              margin-left: 12px;
              margin-top: 25px; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main {
              max-width: 500px;
              padding-left: 30px;
              padding-bottom: 30px; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-typ {
                margin-top: 10px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-typ text {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  padding-top: 20px;
                  color: #9ea0a5; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-typ .avatar-sty {
                  height: 28px;
                  width: 28px; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-one-top {
                padding: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-one-top .user-avatar {
                  margin-left: -20px; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-one-top .user-avatar .name {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #3e3f42; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-one-top .user-avatar .avatar-img {
                    width: 28px;
                    height: 28px;
                    border: 2px #4facfe solid; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .user-one-top .user-time .time {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  color: #9ea0a5; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .msg-div {
                word-wrap: break-word;
                background-color: #25345c;
                border-radius: 0px 10px 10px 10px;
                padding: 20px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-one-main-main .user-one-main .msg-div span {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  color: white; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main {
            width: 100%;
            display: flex;
            justify-content: flex-end; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main {
              max-width: 500px;
              padding-right: 30px;
              padding-bottom: 30px; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .user-sec-top {
                padding: 5px;
                display: flex;
                justify-content: space-between;
                align-items: center; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .user-sec-top .user-avatar {
                  margin-left: -20px; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .user-sec-top .user-avatar .name {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #3e3f42; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .user-sec-top .user-avatar .avatar-img {
                    width: 28px;
                    height: 28px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .user-sec-top .user-time .time {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 12px;
                  line-height: 18px;
                  color: #9ea0a5; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .msg-div {
                border: 1px solid #E6E9EF;
                box-sizing: border-box;
                word-wrap: break-word;
                background-color: white;
                border-radius: 10px 0px 10px 10px;
                padding: 20px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-sec-main-main .user-sec-main .msg-div span {
                  font-family: "Roboto", sans-serif;
                  font-style: normal;
                  font-weight: normal;
                  font-size: 14px;
                  line-height: 22px;
                  color: #3e3f42; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video {
            width: 100%;
            display: flex;
            justify-content: flex-end; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video {
              max-width: 500px;
              padding: 30px; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video {
                width: 100%;
                display: flex;
                justify-content: space-between;
                border: 1px solid #E6E9EF;
                box-sizing: border-box;
                border-radius: 10px;
                background-color: white;
                padding: 8px 16px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-avatar-video {
                  margin-left: -20px; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-avatar-video .avatar-txt-video .title {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: 500;
                    font-size: 14px;
                    line-height: 22px;
                    color: #3e3f42; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-avatar-video .avatar-txt-video .sub-title {
                    font-family: "Roboto", sans-serif;
                    font-style: normal;
                    font-weight: normal;
                    font-size: 12px;
                    line-height: 18px;
                    color: #6b6c6f; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-avatar-video .avatar-sty {
                    height: 60px;
                    width: 60px; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-button-video {
                  padding: 10px 0 0 20px; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-button-video text {
                    font-size: 10px;
                    color: white; }
                  .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .user-sec-top-video .user-button-video .refresh-btn {
                    background-color: #2d9c3c; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .msg-div {
                padding: 5px;
                word-wrap: break-word;
                background-color: white; }
                .main-admin-container .main-body .body-content .right-side-main .right-main .chat-main .user-main-main-sec-video .user-sec-main-video .msg-div span {
                  color: black;
                  font-size: 12px; }
        .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area {
          width: 99.5%;
          background-color: white;
          display: flex;
          justify-content: space-between;
          height: 50px; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area .typing-text {
            width: 88%; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area .typing-text .typing-text-style {
              width: 100%;
              padding-top: 15px;
              padding-left: 15px;
              font-family: "Roboto", sans-serif;
              font-style: normal;
              font-weight: normal;
              font-size: 14px;
              line-height: 22px;
              color: #252529; }
              .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area .typing-text .typing-text-style .MuiInputBase-inputMultiline-47 {
                resize: none;
                overflow: hidden; }
          .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area .type-icons {
            padding-top: 10px; }
            .main-admin-container .main-body .body-content .right-side-main .right-main .typing-area .type-icons .icons {
              color: #9ea0a5;
              height: 20px;
              width: 20px; }

.dot-offline-status {
  align-self: center;
  height: 8px;
  width: 8px;
  background-color: #9ea0a5;
  border-radius: 50%;
  display: inline-block; }

.dot-online-status {
  align-self: center;
  height: 8px;
  width: 8px;
  background-color: green;
  border-radius: 50%;
  display: inline-block; }

.MuiDrawer-paperAnchorRight {
  background: #ffffff;
  border: 1px solid #E6E9EF;
  box-sizing: border-box;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.04);
  border-radius: 4px; }

.add-content-container {
  min-width: 700px; }
  .add-content-container .content-header {
    border-bottom: 1px solid #E6E9EF;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 10px 40px; }
    .add-content-container .content-header .title {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 22px;
      line-height: 32px;
      color: #3e3f42; }
    .add-content-container .content-header .close-btn {
      background: url(/static/media/close.d8435438.svg) no-repeat center;
      background-size: 100%;
      min-width: 35px;
      height: 35px;
      padding: 0; }

.content-tabs .MuiTabs-flexContainer {
  border-bottom: 1px solid #E6E9EF;
  padding-left: 20px; }

.content-tabs .MuiTab-root {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  text-align: center;
  color: #6b6c6f;
  height: 75px;
  text-transform: capitalize; }
  .content-tabs .MuiTab-root.MuiTab-selected {
    font-weight: bold;
    color: #3e3f42; }
  .content-tabs .MuiTab-root .MuiTab-labelContainer {
    padding: 6px 15px; }

.content-tabs .MuiTabs-indicator {
  background: linear-gradient(189.39deg, #00c8fe 0%, #4facfe 100%);
  height: 3px; }

.tab-body .content-row .c-head p {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  color: #6b6c6f;
  margin: 0; }

.tab-body .content-row .c-body .ch-input {
  width: 70%;
  margin-top: 8px; }
  .tab-body .content-row .c-body .ch-input .MuiOutlinedInput-root .MuiOutlinedInput-notchedOutline {
    border: 1px solid #A4ABB3; }
  .tab-body .content-row .c-body .ch-input .MuiOutlinedInput-root.MuiOutlinedInput-focused .MuiOutlinedInput-notchedOutline {
    border: 1px solid #00c8fe; }
  .tab-body .content-row .c-body .ch-input .MuiOutlinedInput-root input {
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 22px;
    color: #25345c;
    padding: 13px 15px; }

.center-msg {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin: auto; }
  .center-msg .chat-icon {
    display: flex;
    justify-content: center; }
    .center-msg .chat-icon img {
      height: 60px; }
  .center-msg span {
    font-size: 12px;
    color: #A4ABB2; }

.header-main {
  margin: 0 auto;
  padding: 12px 96px 0 96px;
  max-width: 1600px; }
  .header-main .MuiToolbar-regular {
    padding: 0 !important; }
    .header-main .MuiToolbar-regular .logo {
      padding: 0 35px 0 0; }
    .header-main .MuiToolbar-regular .user-drop-down-main {
      position: absolute;
      right: 0; }
      .header-main .MuiToolbar-regular .user-drop-down-main .MuiFormControl-root .MuiInput-underline:before {
        border: none !important; }
      .header-main .MuiToolbar-regular .user-drop-down-main .MuiInput-underline:after {
        border: none !important; }
      .header-main .MuiToolbar-regular .user-drop-down-main .user-drop-down {
        float: right;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 26px;
        letter-spacing: 0.538462px;
        color: #475885; }

.new-dropdown-menu .dropdown-title {
  color: #252529; }

.new-dropdown-menu .dropdown-title-highlighted {
  color: #0091f1; }

.breadcrumb-wrapper {
  padding: 20px 0 35px; }

.linkSelected {
  color: black !important; }

.drop-down-wrapper {
  padding: 30px;
  display: flex;
  justify-content: space-between; }
  .drop-down-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .drop-down-wrapper .top-navigation-select-main {
    min-width: 175px; }

.dropdown {
  position: relative;
  display: inline-block; }


.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-search-comp {
  display: flex;
  flex-direction: column;
  min-height: 580px; }
  .main-body-search-comp .sub-title {
    color: #3e3f42;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px;
    margin-top: 20px; }
  .main-body-search-comp .no-result {
    display: flex;
    justify-content: center;
    margin-top: 30px; }
    .main-body-search-comp .no-result .no-result-text {
      color: #3e3f42;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 30px; }
  .main-body-search-comp .patient-heading {
    display: flex;
    justify-content: flex-start;
    padding: 0 30px; }
    .main-body-search-comp .patient-heading span {
      color: #3e3f42;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px; }
    .main-body-search-comp .patient-heading .main-btn-sty {
      background-color: #25345c;
      width: 150px;
      height: 50px; }
      .main-body-search-comp .patient-heading .main-btn-sty:hover {
        background-color: #25345c; }
      .main-body-search-comp .patient-heading .main-btn-sty span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif; }
    .main-body-search-comp .patient-heading .search-main {
      margin-left: 50px; }
      .main-body-search-comp .patient-heading .search-main .debounce-input {
        min-width: 300px;
        padding: 10px 16px; }
  .main-body-search-comp .no-result-main .no-record {
    text-align: center; }
  .main-body-search-comp .list-main-wrapper {
    display: flex;
    flex-direction: column;
    margin: 25px;
    border: 1px solid #E2E5ED; }
    .main-body-search-comp .list-main-wrapper .list-main {
      padding: 0;
      border-bottom: 1px #E2E5ED solid; }

.user-authority {
  color: grey;
  float: right;
  margin-right: 200px; }

.search-main {
  display: flex;
  justify-content: center; }

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 20px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px; }
  .search-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .search-paper-root .global-search-icon-btn {
    padding: 10px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-d {
  display: flex;
  flex-direction: column;
  height: 100%; }
  .main-body-d .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%; }
  .main-body-d .Toastify__toast.Toastify__toast--success {
    padding: 20px; }
  .main-body-d .Toastify__toast.Toastify__toast--error {
    padding: 20px; }
  .main-body-d .Toastify__toast--success {
    background: #25345c; }
  .main-body-d .Toastify__toast-container {
    padding: 0; }
  .main-body-d .patient-heading-d {
    display: flex;
    justify-content: space-between;
    padding: 0 0 30px 0; }
    .main-body-d .patient-heading-d span {
      color: #3e3f42;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
      text-transform: uppercase; }
    .main-body-d .patient-heading-d .main-btn-sty {
      background-color: #25345c;
      width: 150px;
      height: 50px; }
      .main-body-d .patient-heading-d .main-btn-sty:hover {
        background-color: #25345c; }
      .main-body-d .patient-heading-d .main-btn-sty span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif; }
  .main-body-d .tab-main-wrapper {
    display: flex;
    flex-direction: column;
    border: 1px #9ea0a5 solid;
    margin-bottom: 1px; }
    .main-body-d .tab-main-wrapper .tabs-main-list {
      flexGrow: 1;
      backgroundColor: white; }
    .main-body-d .tab-main-wrapper .tab-scroll {
      display: flex;
      flex-direction: column; }
    .main-body-d .tab-main-wrapper .tab-inner-main {
      display: flex;
      flex-direction: column;
      padding: 25px;
      align-items: center; }
      .main-body-d .tab-main-wrapper .tab-inner-main .no-result {
        display: flex;
        justify-content: center; }
        .main-body-d .tab-main-wrapper .tab-inner-main .no-result .no-result-text {
          color: #3e3f42;
          font-size: 20px;
          font-weight: 500;
          font-style: normal;
          font-family: "Roboto", sans-serif;
          margin-left: 30px; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-single {
        border: 1px solid #E6E9EF;
        margin: 15px;
        padding: 15px;
        display: flex;
        width: 80%;
        flex-wrap: wrap;
        justify-content: space-between; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-edit {
        border: 1px solid #E6E9EF;
        margin: 15px;
        padding: 15px;
        display: flex;
        flex-wrap: wrap; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 42%;
        align-items: center;
        border-bottom: 1px solid #E6E9EF;
        padding: 0 15px; }
        .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .field-name {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 26px;
          color: #3e3f42; }
        .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .error-msg {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 26px;
          color: red;
          margin-left: 5px; }
        .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .field-value {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 15px;
          color: #9ea0a5;
          margin-left: 10px; }
        .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .edit-btn {
          margin-top: 10px; }
      .main-body-d .tab-main-wrapper .tab-inner-main .single-edit-div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        width: 42%;
        align-items: center;
        padding: 0 15px;
        margin-bottom: 10px; }
        .main-body-d .tab-main-wrapper .tab-inner-main .single-edit-div .field-name {
          min-width: 100px; }
        .main-body-d .tab-main-wrapper .tab-inner-main .single-edit-div .edit-input {
          width: 450px;
          background-color: white;
          margin-top: 2px !important;
          margin-bottom: 0 !important;
          height: 30px;
          border: 1px #E6E9EF solid;
          padding: 8px;
          font-size: 16px;
          font-weight: 400;
          font-style: normal;
          border-radius: 4px;
          font-family: "Roboto", sans-serif;
          letter-spacing: 0.5px;
          box-sizing: content-box; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.09);
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #008a31; }
  .Toastify .Toastify__toast--success .Toastify__progress-bar {
    display: none; }

.Toastify .Toastify__toast--error {
  background-color: #E6B0AA;
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--error .Toastify__toast-body {
    color: darkred; }
  .Toastify .Toastify__toast--error .Toastify__progress-bar {
    display: none; }

.section-details {
  display: flex; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-pf {
  display: flex;
  flex-direction: column;
  padding: 0 25px; }
  .main-body-pf .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%; }
  .main-body-pf .Toastify__toast.Toastify__toast--success {
    padding: 20px; }
  .main-body-pf .Toastify__toast.Toastify__toast--error {
    padding: 20px; }
  .main-body-pf .Toastify__toast--success {
    background: #25345c; }
  .main-body-pf .Toastify__toast-container {
    padding: 0; }
  .main-body-pf .patient-heading-d {
    display: flex;
    justify-content: flex-start;
    padding: 30px 0 30px 0 !important; }
    .main-body-pf .patient-heading-d .profieImage {
      padding-left: 25px;
      padding-right: 25px;
      padding-bottom: 25px; }
    .main-body-pf .patient-heading-d span {
      color: #3e3f42;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px;
      text-transform: uppercase; }
    .main-body-pf .patient-heading-d .main-btn-sty {
      background-color: #25345c;
      width: 150px;
      height: 50px; }
      .main-body-pf .patient-heading-d .main-btn-sty:hover {
        background-color: #25345c; }
      .main-body-pf .patient-heading-d .main-btn-sty span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif; }
  .main-body-pf .clinicBox {
    border-top-width: 1px;
    border-color: #25345c;
    padding-bottom: 24px; }
    .main-body-pf .clinicBox .headText {
      fontFamily: 'Roboto-Bold';
      color: #252529;
      font-weight: 600;
      fontSize: 20px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase'; }
  .main-body-pf .personal-info-box {
    display: flex;
    flex-wrap: wrap; }
  .main-body-pf .personalInfo {
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    align-items: center;
    border-bottom-width: 1px;
    border-color: black; }
    .main-body-pf .personalInfo .headText {
      fontFamily: 'Roboto-Bold';
      color: #252529;
      font-weight: 600;
      fontSize: 14px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase'; }
    .main-body-pf .personalInfo .parahText {
      fontFamily: 'Roboto-Bold';
      fontSize: 15px;
      lineHeight: 22px;
      marginBottom: 20px;
      textAlign: 'left';
      alignSelf: 'flex-start';
      color: #9ea0a5; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.09);
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #008a31; }
  .Toastify .Toastify__toast--success .Toastify__progress-bar {
    display: none; }

.Toastify .Toastify__toast--error {
  background-color: #E6B0AA;
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--error .Toastify__toast-body {
    color: darkred; }
  .Toastify .Toastify__toast--error .Toastify__progress-bar {
    display: none; }

.main-list-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 24px; }
  .main-list-wrapper .list-head {
    fontFamily: 'Roboto-Bold';
    color: #252529;
    font-weight: 600;
    fontSize: 14px;
    marginBottom: 10px;
    lineHeight: 14px;
    letterSpacing: 0.88px;
    marginTop: 24px;
    alignSelf: 'flex-start';
    textTransform: 'uppercase'; }
  .main-list-wrapper .main-list-div {
    display: flex;
    flex-direction: column; }
    .main-list-wrapper .main-list-div .list-text {
      color: #9ea0a5;
      font-size: 14px;
      line-height: 22px;
      letter-spacing: 0.47px;
      font-family: 'Roboto-Bold';
      margin-bottom: 5px; }

.main-date-wrapper {
  padding-left: 30px;
  padding-right: 30px;
  padding-bottom: 24px; }
  .main-date-wrapper .date-inner {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .main-date-wrapper .date-inner .dateText {
      fontFamily: 'Roboto-Bold';
      color: #252529;
      font-weight: 600;
      fontSize: 14px;
      marginBottom: 10px;
      lineHeight: 14px;
      letterSpacing: 0.88px;
      marginTop: 24px;
      alignSelf: 'flex-start';
      textTransform: 'uppercase'; }
    .main-date-wrapper .date-inner .cliDate {
      color: #9ea0a5;
      font-size: 13px;
      letter-spacing: 0.28px;
      font-family: 'Roboto-Regular';
      margin-left: 10px; }

h2.parahText {
  font-weight: 400;
  font-size: 14px; }

.clinicBox {
  display: flex;
  flex-direction: column;
  align-items: flex-start !important; }
  .clinicBox p {
    margin-top: 0px; }
  .clinicBox .main-list-wrapper,
  .clinicBox .main-date-wrapper {
    padding-left: 0px; }

table .MuiTableRow-root {
  width: 100%;
  display: inline-flex; }

table tbody {
  max-height: 520px;
  overflow-y: auto;
  display: block !important; }

table th, table td {
  width: 25%; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-sc {
  display: flex;
  flex-direction: column; }
  .main-body-sc .sub-title {
    color: #3e3f42;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 30px;
    margin-top: 30px;
    align-self: center; }
  .main-body-sc .no-result {
    display: flex;
    justify-content: center; }
    .main-body-sc .no-result .no-result-text {
      color: red;
      font-size: 20px;
      font-weight: 500;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-top: 30px; }
  .main-body-sc .patient-heading {
    display: flex;
    justify-content: space-between;
    padding: 0 30px; }
    .main-body-sc .patient-heading span {
      color: #3e3f42;
      font-size: 25px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif;
      margin-left: 10px; }
    .main-body-sc .patient-heading .main-btn-sty {
      background-color: #25345c;
      min-width: 150px;
      height: 50px; }
      .main-body-sc .patient-heading .main-btn-sty:hover {
        background-color: #25345c; }
      .main-body-sc .patient-heading .main-btn-sty span {
        color: white;
        font-size: 15px;
        font-weight: 600;
        font-style: normal;
        font-family: "Roboto", sans-serif; }
  .main-body-sc .list-main-wrapper {
    display: flex;
    flex-direction: column;
    margin: 25px;
    border: 1px solid #E2E5ED; }
    .main-body-sc .list-main-wrapper .no-result {
      display: flex;
      justify-content: center; }
      .main-body-sc .list-main-wrapper .no-result .no-result-text {
        color: red;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-family: "Roboto", sans-serif;
        margin: 30px; }
    .main-body-sc .list-main-wrapper .list-main {
      padding: 0;
      border-bottom: 1px #E2E5ED solid; }
      .main-body-sc .list-main-wrapper .list-main .avatar-text {
        display: flex;
        justify-content: space-between; }
  .main-body-sc .search-cross {
    display: flex; }
    .main-body-sc .search-cross .search-main {
      display: flex;
      flex-direction: row; }
      .main-body-sc .search-cross .search-main .search-box {
        width: 400px;
        padding: 0 0 0 30px;
        border: 0.5px #E2E5ED solid;
        border-radius: 5px; }
        .main-body-sc .search-cross .search-main .search-box .search-text {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 22px;
          color: #9ea0a5;
          width: 80%; }
        .main-body-sc .search-cross .search-main .search-box button.MuiButtonBase-root.MuiIconButton-root.search-icon {
          color: #9ea0a5; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-d .Toastify__toast-container--top-left {
  top: -0.2em;
  left: 0;
  width: 100%; }

.main-body-d .Toastify__toast.Toastify__toast--success {
  padding: 20px; }

.main-body-d .Toastify__toast.Toastify__toast--error {
  padding: 20px; }

.main-body-d .Toastify__toast--success {
  background: #25345c; }

.main-body-d .Toastify__toast-container {
  padding: 0; }

.main-body-d .patient-heading-d {
  display: flex;
  justify-content: space-between;
  padding: 0 0 30px 0; }
  .main-body-d .patient-heading-d span {
    color: #3e3f42;
    font-size: 25px;
    font-weight: 600;
    font-style: normal;
    font-family: "Roboto", sans-serif;
    margin-left: 10px;
    text-transform: uppercase; }
  .main-body-d .patient-heading-d .main-btn-sty {
    background-color: #25345c;
    width: 150px;
    height: 50px; }
    .main-body-d .patient-heading-d .main-btn-sty:hover {
      background-color: #25345c; }
    .main-body-d .patient-heading-d .main-btn-sty span {
      color: white;
      font-size: 15px;
      font-weight: 600;
      font-style: normal;
      font-family: "Roboto", sans-serif; }

.main-body-d .tab-main-wrapper {
  display: flex;
  flex-direction: column;
  border: 1px #9ea0a5 solid;
  margin-bottom: 1px; }
  .main-body-d .tab-main-wrapper .total_and_payable {
    display: flex;
    flex-direction: row !important;
    text-align: center;
    justify-content: space-around; }
  .main-body-d .tab-main-wrapper .tabs-main-list {
    flexGrow: 1;
    backgroundColor: white; }
  .main-body-d .tab-main-wrapper .tab-scroll {
    display: flex;
    flex-direction: column; }
  .main-body-d .tab-main-wrapper .tab-inner-main {
    display: flex;
    flex-direction: column;
    padding: 25px;
    align-items: center; }
    .main-body-d .tab-main-wrapper .tab-inner-main .no-result {
      display: flex;
      justify-content: center; }
      .main-body-d .tab-main-wrapper .tab-inner-main .no-result .no-result-text {
        color: #3e3f42;
        font-size: 20px;
        font-weight: 500;
        font-style: normal;
        font-family: "Roboto", sans-serif;
        margin-left: 30px; }
    .main-body-d .tab-main-wrapper .tab-inner-main .main-Content-inner {
      width: 100% !important; }
    .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-single {
      border: 1px solid #E6E9EF;
      margin: 15px;
      padding: 15px;
      display: flex;
      width: 80%;
      flex-wrap: wrap;
      justify-content: space-between; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-single .edit-btn {
        margin-top: 20px; }
    .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 42%;
      align-items: center;
      border-bottom: 1px solid #E6E9EF;
      padding: 0 15px; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .field-name {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 26px;
        color: #3e3f42; }
      .main-body-d .tab-main-wrapper .tab-inner-main .tab-inner-text-div .field-value {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 15px;
        color: #9ea0a5;
        margin-left: 10px; }
    .main-body-d .tab-main-wrapper .tab-inner-main .single-input-div {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      padding: 0 15px;
      margin-bottom: 10px; }
      .main-body-d .tab-main-wrapper .tab-inner-main .single-input-div .edit-input {
        width: 450px;
        background-color: white;
        margin-top: 2px !important;
        margin-bottom: 0 !important;
        margin-left: 25px;
        height: 30px;
        border: 1px #E6E9EF solid;
        padding: 8px;
        font-size: 16px;
        font-weight: 400;
        font-style: normal;
        border-radius: 4px;
        font-family: "Roboto", sans-serif;
        letter-spacing: 0.5px;
        box-sizing: content-box; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.09);
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #008a31; }
  .Toastify .Toastify__toast--success .Toastify__progress-bar {
    display: none; }

.Toastify .Toastify__toast--error {
  background-color: #E6B0AA;
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--error .Toastify__toast-body {
    color: darkred; }
  .Toastify .Toastify__toast--error .Toastify__progress-bar {
    display: none; }

.CareTeamConnection .CareTeamConnectionName {
  width: 30%; }

.CareTeamConnection .CareTeamConnectionFieldValues {
  width: 70%;
  overflow-y: auto;
  text-align: end; }

.CareTeamConnection .CareTeamConnectionFieldValuesInnerDiv {
  display: inline-flex; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-pf {
  padding: 0 80px !important;
  max-width: 1600px;
  margin: 0 auto; }
  .main-body-pf .list-main-wrapper {
    margin-top: 10px; }

.svc-scroll {
  height: 100vh; }

.provider-action {
  float: right;
  background-color: #6aa7ee !important;
  border-radius: 15px !important;
  width: 170px;
  height: 50px;
  color: white !important;
  font-size: 15px;
  margin: 0px 10px !important;
  font-weight: 600;
  font-style: normal;
  font-family: "Roboto", sans-serif; }
  .provider-action:hover {
    background-color: #4facfe !important;
    color: white; }

.name-des {
  display: flex;
  justify-content: space-between; }
  .name-des .name {
    padding: 8px; }

button.MuiButtonBase-root.MuiButton-root.MuiButton-contained.custom-btn.top-section-button.MuiButton-containedPrimary {
  padding: 10px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.create-provider-container {
  padding: 30px; }
  .create-provider-container .create-provider-inner {
    max-width: 1170px;
    margin: 0 auto; }
  .create-provider-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-provider-container .single-edit-div.radio-btn .MuiFormGroup-root {
      flex-direction: row !important;
      flex-wrap: wrap-reverse !important; }
      .create-provider-container .single-edit-div.radio-btn .MuiFormGroup-root label {
        width: 35%; }
    .create-provider-container .single-edit-div .MuiIconButton-label {
      border: 2px solid;
      border-radius: 50%; }
    .create-provider-container .single-edit-div .field-name {
      width: 100%; }
    .create-provider-container .single-edit-div .edit-input {
      width: 100%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px solid #E6E9EF;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-provider-container .single-edit-div .service-multiselect {
      width: 300px; }
    .create-provider-container .single-edit-div .servicetype-multiselect {
      width: 400px; }

.MuiButtonBase-root, .list {
  max-width: 100% !important;
  min-width: auto;
  margin: 2px !important; }

.MuiGrid-root .MuiPaper-root {
  width: 100% !important;
  box-shadow: none !important;
  background: transparent !important;
  border: none !important; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.create-service-container {
  padding: 30px; }
  .create-service-container .create-service-inner {
    max-width: 600px;
    margin: 0 auto; }
  .create-service-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-service-container .single-edit-div .field-name {
      width: 35%; }
    .create-service-container .single-edit-div .edit-input {
      width: 65%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px #E6E9EF solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-service-container .single-edit-div .service-multiselect {
      width: 300px; }
    .create-service-container .single-edit-div .servicetype-multiselect {
      width: 400px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-pf {
  display: flex;
  flex-direction: column;
  padding: 0 25px; }
  .main-body-pf .Toastify__toast-container--top-left {
    top: -0.2em;
    left: 0;
    width: 100%; }
  .main-body-pf .Toastify__toast.Toastify__toast--success {
    padding: 20px; }
  .main-body-pf .Toastify__toast.Toastify__toast--error {
    padding: 20px; }
  .main-body-pf .Toastify__toast--success {
    background: #25345c; }
  .main-body-pf .Toastify__toast-container {
    padding: 0; }
  .main-body-pf .credentialBox {
    padding-left: 30px;
    padding-right: 30px;
    border-top-width: 1px;
    border-color: #25345c;
    padding-bottom: 24px; }
    .main-body-pf .credentialBox .headText {
      font-family: "Roboto", sans-serif;
      color: #252529;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 14px;
      letter-spacing: 0.88px;
      margin-top: 24px;
      text-transform: uppercase; }
    .main-body-pf .credentialBox span {
      font-size: 20px;
      font-weight: 600; }
    .main-body-pf .credentialBox .credList {
      display: flex;
      flex-direction: column; }
      .main-body-pf .credentialBox .credList .singleCred {
        font-size: 14px;
        font-weight: 200;
        margin-top: 10px;
        color: #9ea0a5; }
  .main-body-pf .personal-info-box {
    display: flex;
    flex-wrap: wrap; }
  .main-body-pf .personalInfo {
    padding-bottom: 15px;
    padding-left: 30px;
    padding-right: 30px;
    background-color: white;
    align-items: center;
    border-bottom-width: 1px;
    border-color: black; }
    .main-body-pf .personalInfo .headText {
      font-family: "Roboto", sans-serif;
      color: #252529;
      font-weight: 600;
      font-size: 14px;
      margin-bottom: 10px;
      line-height: 14px;
      letter-spacing: 0.88px;
      margin-top: 24px;
      text-transform: uppercase; }
    .main-body-pf .personalInfo .parahText {
      font-family: "Roboto", sans-serif;
      font-size: 15px;
      line-height: 22px;
      margin-bottom: 20px;
      text-align: left;
      color: #9ea0a5; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.09);
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #008a31; }
  .Toastify .Toastify__toast--success .Toastify__progress-bar {
    display: none; }

.Toastify .Toastify__toast--error {
  background-color: #E6B0AA;
  display: flex;
  justify-content: space-between; }
  .Toastify .Toastify__toast--error .Toastify__toast-body {
    color: darkred; }
  .Toastify .Toastify__toast--error .Toastify__progress-bar {
    display: none; }

.single-edit-div {
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  padding: 0;
  margin-bottom: 10px; }
  .single-edit-div .edit-input {
    width: 100%;
    min-width: 100px;
    background-color: white;
    margin-top: 2px !important;
    margin-bottom: 0 !important;
    height: 30px;
    border: 1px #E6E9EF solid;
    padding: 8px;
    font-size: 16px;
    font-weight: 400;
    font-style: normal;
    border-radius: 4px;
    font-family: "Roboto", sans-serif;
    letter-spacing: 0.5px;
    box-sizing: content-box; }
  .single-edit-div .headText {
    font-family: "Roboto", sans-serif;
    color: #252529;
    font-weight: 600;
    font-size: 14px;
    margin-bottom: 10px;
    line-height: 14px;
    letter-spacing: 0.88px;
    margin-top: 24px;
    text-transform: uppercase; }

.provider-image-wrapper {
  align-items: center;
  padding: 30px;
  width: 200px; }
  .provider-image-wrapper .profieImage {
    padding-left: 25px;
    padding-right: 25px; }

.img-uploader {
  text-align: center;
  width: 180px; }
  .img-uploader .fileUploader .fileContainer {
    box-shadow: none;
    margin-left: 15px;
    margin-right: 15px;
    align-items: flex-start; }
  .img-uploader .fileUploader .chooseFileButton {
    min-width: 160px; }
  .img-uploader .fileUploader .uploadPicturesWrapper .uploadPictureContainer {
    width: 60%;
    margin: 16px; }

.update-btn {
  display: block;
  margin: 0 auto; }

.clinicBox {
  border-top-width: 1px;
  border-color: #25345c;
  padding-bottom: 24px; }
  .clinicBox .headText {
    font-family: "Roboto", sans-serif;
    color: #252529;
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 10px;
    line-height: 14px;
    letter-spacing: 0.88px;
    margin-top: 24px;
    text-transform: uppercase; }

.profile-img-updater .fileUploader .fileContainer {
  margin: 0px;
  padding: 0px;
  box-shadow: none;
  margin-left: 15px;
  margin-right: 15px;
  align-items: flex-start; }

.profile-img-updater .fileUploader .chooseFileButton {
  min-width: 90px; }

.profile-img-updater .fileUploader .uploadPicturesWrapper .uploadPictureContainer {
  width: 60%; }

.clinicBoxFieldsMain {
  display: flex; }

.fileContainer {
  padding: 0 !important; }

.Affliation-Wrapper {
  display: flex; }

.MuiButton-containedPrimary{
  float: right;
}
.Download-btn{
  color:white;
  float: right;
}
.tab-main-wrapper {
  /*max-width: 1600px;*/
  /*padding: 0px 96px;*/
  margin:0 auto;
  width:100%;

}
table td{
  word-break: break-all;
}

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.appt-table {
  max-width: 700px;
  margin: 20px auto;
  width: 100%; }
  .appt-table .schedule-row {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px 20px; }
  .appt-table .schedule-left {
    text-align: left; }
    .appt-table .schedule-left .main-head-text {
      text-align: left; }
  .appt-table .schedule-right .next-arrow-btn {
    background: transparent;
    border: none;
    transform: rotate(180deg);
    box-shadow: none; }

.main-dialog .MuiDialog-paperScrollPaper {
  text-align: center; }

.days-list {
  width: -webkit-max-content !important;
  width: -moz-max-content !important;
  width: max-content !important;
  margin: 0 auto !important; }
  .days-list .day-name {
    width: 93px; }

.time-Picker-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  margin: 0 auto;
  text-align: left; }

.no-result {
  display: flex;
  justify-content: center; }
  .no-result .no-result-text {
    color: #3e3f42;
    font-size: 20px;
    font-weight: 500;
    font-style: normal;
    font-family: "Roboto", sans-serif; }

.main-dialog .MuiDialog-paperWidthSm {
  min-width: 700px;
  min-height: 400px; }

.main-dialog .MuiDialog-paper {
  display: flex;
  position: relative;
  margin: 0;
  overflow-y: unset;
  padding-bottom: 5px; }

.main-dialog .MuiDialog-paperScrollPaper {
  max-height: 100% !important;
  padding: 20px; }

.main-dialog .table-main {
  background-color: #f2f2f2;
  height: 400px; }
  .main-dialog .table-main table {
    width: 100%;
    border-collapse: separate;
    border-spacing: 0 20px; }
  .main-dialog .table-main .table-head {
    border-bottom: 2px solid #EBEBEB;
    padding-bottom: 8px;
    margin-right: 8px; }
  .main-dialog .table-main tr {
    display: flex;
    margin-bottom: 10px;
    justify-content: space-around;
    padding: 0 30px; }
  .main-dialog .table-main th {
    color: grey;
    font-size: 14px;
    font-weight: 500;
    text-align: left; }
  .main-dialog .table-main .sec-th {
    text-align: center; }
  .main-dialog .table-main .sec-td {
    text-align: right; }
  .main-dialog .table-main td {
    width: 62%;
    font-family: "Roboto", sans-serif;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 22px;
    color: #3e3f42;
    text-align: left;
    word-break: break-word; }
    .main-dialog .table-main td button {
      width: 111px;
      background-color: white;
      margin-left: 5px;
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #3e3f42;
      padding: 8px 18px;
      border-radius: 4px;
      border: 2px solid #EBEBEB; }
    .main-dialog .table-main td .MuiSelect-root {
      border-radius: 5px;
      background-color: white;
      padding: 8px 18px;
      border: 2px solid #EBEBEB; }
      .main-dialog .table-main td .MuiSelect-root :focus {
        background: transparent; }
    .main-dialog .table-main td .MuiSelect-selectMenu {
      font-family: "Roboto", sans-serif;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 22px;
      color: #3e3f42;
      padding: 0 20px 0 0;
      width: 60px;
      text-align: left; }
    .main-dialog .table-main td .MuiInput-underline:after {
      border-bottom: 0 !important; }
    .main-dialog .table-main td .MuiInput-underline:before {
      border-bottom: 0 !important; }
    .main-dialog .table-main td .MuiInput-underline:hover {
      border-bottom: 0 !important; }
    .main-dialog .table-main td svg.MuiSvgIcon-root.MuiSelect-icon {
      background: url(/static/media/dropdownarrow.34364dd9.svg) no-repeat;
      width: 6px;
      height: 10px;
      padding-right: 14px; }
      .main-dialog .table-main td svg.MuiSvgIcon-root.MuiSelect-icon path {
        display: none; }
    .main-dialog .table-main td .MuiSelect-icon {
      top: calc(50% - 5px);
      right: 0;
      color: rgba(0, 0, 0, 0.54);
      position: absolute;
      pointer-events: none; }
  .main-dialog .table-main .MuiButton-root-1 {
    border-radius: 5px;
    min-width: 35px; }
  .main-dialog .table-main .MuiButtonBase-root {
    vertical-align: unset; }

.main-dialog .dialog-head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 10px 10px 30px; }
  .main-dialog .dialog-head .search-cross {
    display: flex; }
  .main-dialog .dialog-head span {
    font-size: 16px;
    font-weight: 800; }
  .main-dialog .dialog-head .search-main {
    border: 1px #E6E9EF solid;
    padding-left: 10px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-right: 10px; }
    .main-dialog .dialog-head .search-main input {
      color: #3e3f42;
      font-size: 14px; }
    .main-dialog .dialog-head .search-main svg {
      height: 20px; }
    .main-dialog .dialog-head .search-main :hover {
      background: transparent; }

.main-dialog .Toastify__toast-container--top-left {
  top: -0.2em;
  left: 0;
  width: 100%; }

.main-dialog .Toastify__toast.Toastify__toast--success {
  padding: 20px; }

.main-dialog .Toastify__toast--success {
  background: #25345c; }

.main-dialog .Toastify__toast-container {
  padding: 0; }

.close-btn {
  background: url(/static/media/close.d8435438.svg) no-repeat center;
  background-size: 100%;
  height: 30px;
  padding: 0; }

.MuiButton-root-1 {
  min-width: 30px !important;
  margin-left: 15px !important; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.MuiTableCell-alignLeft .service-type-desc {
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
  overflow: hidden;
  white-space: unset;
  text-overflow: ellipsis; }

.main-body-c {
  margin: 30px 0 0 0; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.create-service-container {
  padding: 30px; }
  .create-service-container .create-service-inner {
    max-width: 600px;
    margin: 0 auto; }
  .create-service-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-service-container .single-edit-div .field-name {
      width: 35%; }
    .create-service-container .single-edit-div .edit-input {
      width: 65%;
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px #E6E9EF solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-service-container .single-edit-div .service-multiselect {
      width: 300px; }
    .create-service-container .single-edit-div .servicetype-multiselect {
      width: 400px; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0px;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62; }

.content-block-main {
  display: flex; }
  .content-block-main .content-block-8blocks {
    max-width: 448px;
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin: 0 24px 0 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 33px;
          padding: 0 24px 0 22px;
          color: #8F9EAE; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          ont-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 24px 0 0; }
  .content-block-main .content-block-selected-block {
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: left !important; }

.patients-section .date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 140px;
  display: inline-block; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.MuiFormControl-root {
  width: 100%; }

.backdrop {
  background-color: red; }

.modal-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline;
  margin: 0 0 0 10px; }

.main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.modal-main h2 {
  padding: 0 !important; }

.modal-main h3 {
  padding: 15px 0 5px 0 !important;
  margin: 0 !important; }

.modal-main .field-wrapper {
  padding: 0;
  margin: 0; }

.modal-main .modal-header .tab-main-wrapper-tabs .MuiTabs-flexContainer .MuiTab-textColorInherit.Mui-selected {
  border-bottom: none !important; }

.modal-sections-main {
  display: flex; }
  .modal-sections-main .importance-level-main {
    padding: 0 15px 0 15px; }
    .modal-sections-main .importance-level-main .importance-level {
      overflow: hidden; }
      .modal-sections-main .importance-level-main .importance-level .field-wrapper {
        padding: 0;
        float: left; }
        .modal-sections-main .importance-level-main .importance-level .field-wrapper .MuiFormControlLabel-labelPlacementStart {
          padding: 0px;
          margin: 0px; }
    .modal-sections-main .importance-level-main .field-wrapper {
      padding: 0; }
      .modal-sections-main .importance-level-main .field-wrapper .MuiFormControlLabel-labelPlacementStart {
        padding: 0px;
        margin: 0px; }
  .modal-sections-main .second-section {
    height: 80px;
    overflow-y: scroll;
    padding: 0 15px 0 15px; }
    .modal-sections-main .second-section .field-wrapper {
      padding: 0; }
      .modal-sections-main .second-section .field-wrapper .MuiFormControlLabel-labelPlacementStart {
        padding: 0px;
        margin: 0px; }
  .modal-sections-main .require-notes {
    display: flex;
    justify-content: space-between; }

.MuiFormControl-root {
  width: 100%; }

.modal-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline;
  margin: 0 0 0 10px; }

.modal-main .field-wrapper {
  margin-bottom: 2px !important; }

.capitalize {
  text-transform: capitalize; }

.form-wrapper {
  display: flex; }
  .form-wrapper input {
    width: 180px !important; }
  .form-wrapper MuiFormControl-root {
    max-width: 50px !important; }
  .form-wrapper .high-end-low-end-lable {
    width: 220px !important;
    line-height: 34px; }
  .form-wrapper .high-end-low-end {
    max-width: 50px !important; }

.controls-main {
  display: flex; }
  .controls-main .custom-btn {
    display: block; }
  .controls-main .controls-left {
    padding: 5px;
    width: 50%; }
  .controls-main .controls-right {
    padding: 5px;
    width: 50%; }

.domain-elements-modal {
  min-width: 900px; }
  .domain-elements-modal .elements-controls-main {
    flex-direction: column; }
    .domain-elements-modal .elements-controls-main .elements-controls-left {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .domain-elements-modal .elements-controls-main .elements-controls-left > .field-wrapper {
        width: 36%;
        margin-right: 16px; }
    .domain-elements-modal .elements-controls-main .elements-details .rx-heading {
      margin-bottom: 5px;
      font-size: 16px; }
    .domain-elements-modal .elements-controls-main .elements-details .importance-level-Wrapper {
      display: flex; }
    .domain-elements-modal .elements-controls-main .elements-details .element-fields {
      display: flex;
      flex-wrap: wrap; }
      .domain-elements-modal .elements-controls-main .elements-details .element-fields > .field-wrapper {
        width: 28%;
        margin-right: 16px; }
        .domain-elements-modal .elements-controls-main .elements-details .element-fields > .field-wrapper .MuiInput-root {
          width: 100%; }
        .domain-elements-modal .elements-controls-main .elements-details .element-fields > .field-wrapper .MuiInputLabel-formControl {
          white-space: nowrap;
          width: 94%;
          text-overflow: ellipsis;
          overflow: hidden; }
  .domain-elements-modal .map-to-from-wrap {
    display: block;
    width: 100%; }
  .domain-elements-modal .map-to-from {
    display: flex; }
    .domain-elements-modal .map-to-from > .MuiFormControl-root {
      width: 100%;
      margin-right: 16px; }
    .domain-elements-modal .map-to-from .map-to-from.w-control {
      width: 21%;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 35px; }
  .domain-elements-modal .map-btn-wrap {
    display: flex;
    margin: 16px 0; }

.accordion-wrapper {
  margin: 24px 0; }

.accordion-content-wrap {
  width: 100%; }

.del-icon {
  margin-top: 16px; }

.btn-wrapper {
  padding-top: 20px; }

.field-wrapper.tag-color {
  margin: 30px 0 0 0; }

.img-uploader {
  text-align: center;
  width: 180px; }
  .img-uploader .fileUploader .fileContainer {
    box-shadow: none;
    margin-left: 15px;
    margin-right: 15px;
    align-items: flex-start; }
  .img-uploader .fileUploader .chooseFileButton {
    min-width: 160px; }
  .img-uploader .fileUploader .uploadPicturesWrapper .uploadPictureContainer {
    width: 60%;
    margin: 16px; }

.provider-image-wrapper {
  align-items: center;
  padding: 30px;
  width: 200px; }
  .provider-image-wrapper .botImage {
    padding-left: 25px;
    padding-right: 25px; }

.manage-benefits-content {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .manage-benefits-content .field-wrapper.benefits {
    width: 500px; }
  .manage-benefits-content .score {
    width: 58px; }
  .manage-benefits-content .icon-wrapper {
    display: flex; }
    .manage-benefits-content .icon-wrapper i {
      padding: 0 0 0 20px; }
      .manage-benefits-content .icon-wrapper i img {
        width: 17px; }
        .manage-benefits-content .icon-wrapper i img[alt="Delete"] {
          width: 15px; }

.manage-question-content {
  display: flex;
  align-items: self-start;
  justify-content: flex-start;
  flex-direction: column; }
  .manage-question-content .field-wrapper.question {
    width: 100%; }
  .manage-question-content .score {
    width: 58px; }
  .manage-question-content .icon-wrapper {
    display: flex; }
    .manage-question-content .icon-wrapper i {
      padding: 0 0 0 20px; }
      .manage-question-content .icon-wrapper i img {
        width: 17px; }
        .manage-question-content .icon-wrapper i img[alt="Delete"] {
          width: 15px; }

.icon-wrapper-add-benefit {
  display: flex;
  margin-bottom: 50px; }
  .icon-wrapper-add-benefit p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.5px;
    display: inline; }
    .icon-wrapper-add-benefit p svg {
      padding: 0 12px 0 0; }
  .icon-wrapper-add-benefit .blue-text {
    display: flex;
    align-items: center;
    justify-content: space-between; }

.img-uploader {
  text-align: center;
  width: 180px; }
  .img-uploader .fileUploader .fileContainer {
    box-shadow: none;
    margin-left: 15px;
    margin-right: 15px;
    align-items: flex-start; }
  .img-uploader .fileUploader .chooseFileButton {
    min-width: 160px; }
  .img-uploader .fileUploader .uploadPicturesWrapper .uploadPictureContainer {
    width: 60%;
    margin: 16px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.values {
  float: left;
  background-color: #f2f9ff;
  border-radius: 25px !important;
  color: #6b6c6f;
  font-size: 15px;
  font-style: normal;
  padding: 10px; }

.button {
  float: left;
  background-color: #f2f9ff !important;
  border-radius: 2px !important;
  border-left-width: thin !important;
  border-color: #0079ff !important;
  color: #0079ff !important;
  margin: 5px !important; }
  .button:hover {
    background-color: #ffffff !important;
    color: black !important; }

.appearsInText {
  color: #00c8fe !important;
  font-size: 15px;
  font-weight: 600; }

.MuiButton-textPrimary {
  color: #0091f1 !important; }

.create-service-container {
  padding: 30px; }
  .create-service-container .create-service-inner {
    max-width: 600px;
    margin: 0 auto; }
  .create-service-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-service-container .single-edit-div .field-name {
      width: 35%; }
    .create-service-container .single-edit-div .edit-input {
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px #E6E9EF solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-service-container .single-edit-div .profileElementType-multiselect {
      padding-left: 0px !important;
      align-content: flex-start;
      justify-content: flex-start;
      justify-self: flex-start;
      width: 400px !important; }
    .create-service-container .single-edit-div .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .MuiInput-underline:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect {
      padding-left: 0px !important;
      width: 400px !important; }
    .create-service-container .single-edit-div .menuItem {
      padding-left: 0px !important;
      display: inline-flex;
      justify-content: flex-start; }

.root {
  display: inline-flex;
  justify-content: flex-start;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  border: 1px #E6E9EF solid;
  padding: 4px;
  box-sizing: content-box;
  width: 400px; }

.chip {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.list {
  list-style-type: none; }

.save-btn-txt {
  color: white; }

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px; }
  .search-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .search-paper-root .global-search-icon-btn {
    padding: 10px; }

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px; }
  .filter-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .filter-paper-root .global-search-icon-btn {
    padding: 10px; }

.search-main .search-input {
  border: none !important;
  min-width: 500px !important; }

.search-main .search-paper-root {
  box-shadow: none;
  border-radius: 0; }
  .search-main .search-paper-root input {
    font-size: 12px;
    width: 234px; }

.MuiSelect-select.MuiSelect-select {
  color: #0091f1 !important; }

.content-main .filtered-values {
  float: right; }
  .content-main .filtered-values .MuiPaper-elevation1 {
    background-color: transparent;
    padding: 20px 5px 0 5px;
    box-shadow: none; }

@media screen and (max-width: 1367px) {
  .bMYmCz {
    max-width: 190px !important; } }

.dJshAA div:first-child {
  white-space: break-spaces !important;
  overflow: hidden;
  text-overflow: ellipsis; }

.value-with-bg {
  margin: 2px; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0px;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62; }

.content-main .table-actions.filter {
  justify-content: flex-end !important; }

.content-block-main {
  display: flex; }
  .content-block-main .content-block-8blocks {
    max-width: 448px;
    border-right: 1px solid rgba(0, 0, 0, 0.12); }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: space-between;
        text-align: center;
        margin: 0 24px 0 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: Roboto;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 33px;
          padding: 0 24px 0 22px;
          color: #8F9EAE; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          ont-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          /* identical to box height, or 171% */
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 24px 0 0; }
  .content-block-main .content-block-selected-block {
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: left !important; }

.patients-section .date {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 140px;
  display: inline-block; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.patients-section .date {
  font-size: 12px;
  padding: 0 20px 0 0;
  width: 180px;
  display: inline-block;
  padding: 15px 50px 0px 0px; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.baseColor {
  color: #00c8fe; }

.flag {
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0; }

.patients-section .date {
  font-size: 12px;
  padding: 0 20px 0 0;
  width: 180px;
  display: inline-block;
  padding: 15px 50px 0px 0px; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .red-tag {
  background: #f6e2f8;
  color: #af4fba;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.baseColor {
  color: #00c8fe; }

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  border-bottom: 1px solid #F0F2F8;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.535714px;
  color: #0091F1; }

.flag {
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0; }

.dct-Report-main .dct-Report-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .dct-Report-main .dct-Report-main-header .dct-Report-main-header-info h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.714286px;
    color: #1F1E25;
    padding: 0 0 10px 0;
    margin: 0; }
  .dct-Report-main .dct-Report-main-header .dct-Report-main-header-info p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    letter-spacing: 0.535714px;
    color: #2B3A62; }
  .dct-Report-main .dct-Report-main-header .dct-Report-main-header-tag {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .dct-Report-main .dct-Report-main-header .dct-Report-main-header-tag .dct-assign-shape {
      width: 24px;
      height: 6px;
      background: #4facfe !important; }
    .dct-Report-main .dct-Report-main-header .dct-Report-main-header-tag p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      padding: 0 0 0 9px;
      letter-spacing: 0.5px; }

.main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: capitalize !important; }

.Dct-Report-Period-Select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  width: 60px !important; }

.Period-Filter {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: baseline;
  width: 200px !important; }

.filtered-values {
  float: right; }
  .filtered-values .MuiPaper-elevation1 {
    background-color: transparent;
    padding: 20px  5px 0 5px;
    box-shadow: none; }

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px; }
  .filter-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .filter-paper-root .global-search-icon-btn {
    padding: 10px; }

.dct-status-select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  width: 400px !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(1) .legend-icon {
  height: 5px;
  width: 12px;
  border-radius: 50%;
  margin: 0 20px 0  0;
  fill: #A7DB63 !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(1) .legend-label {
  fill: #A7DB63 !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(2) .legend-icon {
  height: 5px;
  width: 12px;
  border-radius: 50%;
  margin: 0 20px 0  0;
  fill: #E05C6E !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(2) .legend-label {
  fill: #E05C6E !important; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .tadct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px !important; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62; }

.content-block-main {
  display: flex;
  height: 100vh;
  margin: 0 0 50px 0; }
  .content-block-main .content-block-8blocks {
    max-width: 500px;
    width: 500px;
    height: inherit;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden; }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 24px 0 0;
        border: none;
        outline: none; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
  .content-block-main .content-block-selected-block {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: flex-start !important; }

.patients-section .date {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 180px;
  display: inline-block;
  padding: 15px 50px 0px 0px; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.ContentBlock-Editor-Wrapper {
  overflow-y: auto;
  width: 60%; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.tab-main-wrapper .tab-main-wrapper-tabs {
  border-top: 1px solid #E6E9EF;
  border-bottom: 1px solid #E6E9EF; }

.tab-main-wrapper .MuiTab-root {
  min-width: 130px !important;
  text-align: left !important; }

.MuiTab-textColorInherit.Mui-selected {
  border-bottom: 1px solid #E6E9EF !important; }

span.MuiTab-wrapper {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  letter-spacing: 0.5px;
  color: #0091F1; }

.no-content-block {
  overflow-y: auto;
  overflow-x: hidden;
  padding: 94px 60px 0px 80px;
  width: 668px; }
  .no-content-block h3 {
    color: #cad1e0;
    text-align: center;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 18px;
    letter-spacing: 0.5px; }
  .no-content-block .react-switch {
    vertical-align: middle;
    margin-left: 4px; }

.description-wrapper {
  margin-top: 30px; }
  .description-wrapper .sub-title {
    margin-top: 10px; }

.margin-top {
  margin-top: 10px !important; }

.questionContentWrapper {
  display: flex;
  width: 100%;
  justify-content: space-between; }

.qustionWrapper {
  flex-basis: 35%;
  border-right: 1px solid rgba(0, 0, 0, 0.12); }
  .qustionWrapper .questionHeader {
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    padding: 20px; }
    .qustionWrapper .questionHeader h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 20px;
      line-height: 40px;
      margin: 0;
      letter-spacing: 0.538462px;
      padding: 0;
      color: #2B3A62; }
    .qustionWrapper .questionHeader .icon-wrapper {
      height: 32px;
      width: 32px;
      border-radius: 50%;
      background: #0091f1;
      display: flex;
      align-items: center;
      justify-content: center;
      text-align: center;
      margin: 0;
      border: none;
      outline: none; }
  .qustionWrapper .questionsListWrapper {
    padding: 15px;
    margin: 15px 0;
    height: calc(100vh - 141px);
    overflow-y: overlay;
    overflow-x: hidden; }
    .qustionWrapper .questionsListWrapper .mainQuestion {
      margin-bottom: 20px; }
      .qustionWrapper .questionsListWrapper .mainQuestion:last-child {
        margin-bottom: 0px; }
      .qustionWrapper .questionsListWrapper .mainQuestion .questionContent {
        position: relative; }
        .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionNo {
          background-color: #c1c1c1;
          position: absolute;
          left: 5px;
          top: -23px;
          z-index: 222;
          width: auto;
          padding: 0 10px;
          height: 24px;
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 13px;
          line-height: 23px;
          letter-spacing: 0.433333px;
          border-radius: 15px;
          color: #FFFFFF;
          text-align: center; }
        .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper {
          padding: 10px;
          border: 1px solid #e0e0e0;
          box-shadow: none;
          border-radius: 5px !important;
          margin: 0 !important; }
          .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper::before {
            display: none; }
          .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper.Mui-expanded {
            border-color: #0091f1 !important; }
            .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper.Mui-expanded .questionNo {
              background-color: #0091f1; }
          .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper .questionInner {
            min-height: auto;
            padding: 0; }
            .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper .questionInner .MuiAccordionSummary-content {
              margin: 0 !important; }
            .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper .questionInner h4 {
              font-size: 14px;
              font-family: Roboto;
              font-style: normal;
              font-weight: normal;
              letter-spacing: 0.642857px;
              color: #252529;
              margin-left: 10px; }
            .qustionWrapper .questionsListWrapper .mainQuestion .questionContent .questionAccordionWrapper .questionInner .MuiIconButton-root {
              margin: 0;
              padding: 0; }

.accordionInnerWrapper {
  display: block !important;
  padding: 0 !important; }
  .accordionInnerWrapper .questionHeader {
    padding: 10px;
    padding-top: 0; }
  .accordionInnerWrapper .questionsListWrapper {
    height: inherit;
    padding: 15px 0;
    margin-bottom: 0; }
  .accordionInnerWrapper .questionNo {
    background-color: #c1c1c1 !important; }
  .accordionInnerWrapper .Mui-expanded .questionNo {
    background-color: #0091f1 !important; }
  .accordionInnerWrapper .acChildInnerWrapper .questionNo {
    background-color: #c1c1c1 !important; }
  .accordionInnerWrapper .acChildInnerWrapper .Mui-expanded .questionNo {
    background-color: #0091f1 !important; }

.contentWrapper {
  flex-basis: 65%; }
  .contentWrapper .tab-main-wrapper .tab-main-wrapper-tabs {
    border-top: none; }
  .contentWrapper .innerWrapper {
    padding: 15px;
    margin: 15px 0;
    height: calc(100vh - 109px);
    overflow-y: overlay;
    overflow-x: hidden; }
    .contentWrapper .innerWrapper .fieldWrapper {
      box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.05);
      padding: 15px;
      margin-bottom: 15px;
      border: 1px solid #efefef;
      border-radius: 5px; }
      .contentWrapper .innerWrapper .fieldWrapper .MuiFormLabel-filled, .contentWrapper .innerWrapper .fieldWrapper .Mui-focused {
        transform: scale(1); }
      .contentWrapper .innerWrapper .fieldWrapper label {
        font-size: 14px !important;
        margin-bottom: 5px !important;
        color: #000 !important;
        font-family: 'Roboto';
        font-weight: normal !important;
        line-height: 21px !important;
        letter-spacing: 0.642857px !important; }
      .contentWrapper .innerWrapper .fieldWrapper h4 {
        margin: 0;
        margin-bottom: 10px; }
      .contentWrapper .innerWrapper .fieldWrapper .subWrapper {
        margin-bottom: 20px; }
    .contentWrapper .innerWrapper .btnWrapper {
      display: flex;
      justify-content: flex-end; }
      .contentWrapper .innerWrapper .btnWrapper .custom-btn {
        margin-left: 15px; }

.groupInnerWrapper {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  grid-gap: 20px;
  gap: 20px; }

.groupIconWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  flex-basis: 50%;
  height: 212px; }

.groupIconWrapper .img-uploader img {
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px; }

.colorPickerWrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-shadow: 0 1px 4px rgba(0, 0, 0, 0.05);
  padding: 15px;
  margin-bottom: 15px;
  border: 1px solid #efefef;
  border-radius: 5px;
  flex-basis: 50%;
  height: 212px;
  position: relative; }

.colorPickerWrapper .MuiInput-formControl .MuiInputBase-input {
  cursor: pointer; }

.colorPickerWrapper .MuiInput-formControl {
  padding: 6px 23px;
  background: #3f4257;
  border-radius: 30px;
  color: #fff !important;
  font-weight: 300;
  font-size: 14px;
  transition: all .2s ease-in;
  cursor: pointer;
  outline: none;
  border: none;
  width: 160px;
  margin: 20px auto;
  display: flex;
  height: 28.8px; }

.colorPickerWrapper .MuiInput-formControl:before, .colorPickerWrapper .MuiInput-formControl:after {
  display: none !important; }

.contentWrapper .innerWrapper .fieldWrapper .colorPickerWrapper .MuiTextField-root label {
  text-align: center;
  z-index: 2;
  color: #fff !important;
  font-weight: 300 !important;
  font-size: 14px !important;
  margin: 0 auto;
  right: 0;
  cursor: pointer; }

.colorBoxWrapper {
  height: 100px;
  width: 100px;
  display: block;
  border: 1px solid #e2e2e2;
  border-radius: 5px;
  padding: 5px; }

.colorBoxInner {
  width: 100%;
  height: 100%;
  background-color: #ccc; }

.chrome-picker {
  position: absolute;
  left: 60px;
  top: -10px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.MuiFormControl-root {
  width: 100%; }

.selected-content-main-wrapper {
  padding: 56px 0 56px 0; }
  .selected-content-main-wrapper h2 {
    padding: 0 0 56px 0;
    margin: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 21px;
    letter-spacing: 0.642857px;
    color: #252529; }
  .selected-content-main-wrapper .display-logics-main .display-logics-text {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    letter-spacing: 0.5px;
    color: #515D7D;
    mix-blend-mode: normal;
    opacity: 0.75;
    padding: 0 0 0 0;
    margin: 0 0 40px 0; }
  .selected-content-main-wrapper .field-wrapper .MuiFormControl-root label {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 11px;
    text-transform: uppercase;
    color: #6F7EA7;
    padding: 0 0 10px 0; }
  .selected-content-main-wrapper .field-wrapper .MuiFormControl-root .MuiInput-formControl {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #1F1E25; }
  .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-header {
    display: flex;
    align-items: center;
    justify-content: space-between; }
  .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .field-wrapper.choices {
      width: 500px; }
    .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .score {
      width: 58px; }
    .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .icon-wrapper {
      display: flex; }
      .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .icon-wrapper i {
        padding: 0 0 0 20px; }
        .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .icon-wrapper i img {
          width: 17px; }
          .selected-content-main-wrapper .manage-choices-wrapper .manage-choices-content .icon-wrapper i img[alt="Delete"] {
            width: 15px; }
  .selected-content-main-wrapper .icon-wrapper-add-choice {
    display: flex;
    margin-bottom: 50px; }
    .selected-content-main-wrapper .icon-wrapper-add-choice p {
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 15px;
      line-height: 18px;
      letter-spacing: 0.5px;
      display: inline; }
      .selected-content-main-wrapper .icon-wrapper-add-choice p svg {
        padding: 0 12px 0 0; }
    .selected-content-main-wrapper .icon-wrapper-add-choice .blue-text {
      display: flex;
      align-items: center;
      justify-content: space-between; }
  .selected-content-main-wrapper .selected-content-main-wrapper-variable {
    display: flex; }
    .selected-content-main-wrapper .selected-content-main-wrapper-variable .selected-content-main-wrapper-variable-content {
      width: 100%; }
      .selected-content-main-wrapper .selected-content-main-wrapper-variable .selected-content-main-wrapper-variable-content .icon-wrapper {
        height: 32px;
        width: 192px;
        padding: 0 0 40px 0;
        display: flex;
        align-items: center;
        justify-content: left;
        text-align: center; }
      .selected-content-main-wrapper .selected-content-main-wrapper-variable .selected-content-main-wrapper-variable-content h2 {
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 18px;
        line-height: 27px;
        /* or 150% */
        letter-spacing: 0.642857px;
        color: #1F1E25; }
    .selected-content-main-wrapper .selected-content-main-wrapper-variable .selected-content-main-wrapper-variable-icon {
      width: 66px; }
      .selected-content-main-wrapper .selected-content-main-wrapper-variable .selected-content-main-wrapper-variable-icon svg {
        width: 40px;
        height: 40px; }

.MuiFormControl-root {
  width: 100%; }

.modal-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline;
  margin: 0 0 0 10px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.add-DCT-main .serach-field {
  background: #F0F2F8;
  padding: 24px 0 24px 24px;
  margin: 20px 0 0 0; }
  .add-DCT-main .serach-field .MuiInput-underline:before {
    border: none !important; }

.add-DCT-main .scrollabel-content {
  padding: 25px 56px 0 56px;
  height: 240px;
  overflow-y: auto;
  overflow-x: hidden; }
  .add-DCT-main .scrollabel-content .selected-dct {
    border-bottom: 1px solid #F0F2F8;
    padding: 20px 0 20px 0 !important;
    align-items: center; }
    .add-DCT-main .scrollabel-content .selected-dct .MuiFormControlLabel-labelPlacementStart {
      height: 23px !important; }
    .add-DCT-main .scrollabel-content .selected-dct .field-wrapper-content .field-wrapper-content-heading {
      display: flex;
      justify-content: space-between;
      width: -webkit-max-content;
      width: -moz-max-content;
      width: max-content; }
      .add-DCT-main .scrollabel-content .selected-dct .field-wrapper-content .field-wrapper-content-heading h3 {
        padding: 0 !important;
        margin: 0 10px 7px 0 !important;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 18px;
        line-height: 21px;
        letter-spacing: 0.642857px;
        color: #1F1E25;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        width: 362px; }
    .add-DCT-main .scrollabel-content .selected-dct p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      padding: 0;
      margin: 0;
      letter-spacing: 0.5px;
      color: #515D7D; }

.add-DCT-main .btn-wrapper {
  padding: 20px  20px 20px 56px; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62;
          min-height: 24px; }

.content-block-main {
  display: flex;
  height: 100vh;
  margin: 0 0 50px 0; }
  .content-block-main .content-block-8blocks {
    max-width: 500px;
    width: 500px;
    height: inherit;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden; }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .dct-inner {
        display: block !important; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 24px 0 0;
        border: none;
        outline: none; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item-dct {
      display: flex;
      padding: 33px 0 25px 0;
      border: 1px solid #4B94C8;
      position: relative; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .dct-indicator {
        background: #4B94C8;
        position: absolute;
        left: 80px;
        top: -12px;
        z-index: 222;
        width: 72px;
        height: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.433333px;
        border-radius: 15px;
        color: #FFFFFF;
        text-align: center; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header .MuiIconButton-edgeEnd {
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header .MuiAccordionSummary-content {
        padding: 0;
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header ::before {
        display: none;
        box-shadow: none !important; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .MuiAccordion-root:before {
        display: none; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .MuiPaper-elevation1 {
        box-shadow: none !important;
        background: none;
        width: inherit;
        overflow: hidden;
        padding: 0px;
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
  .content-block-main .content-block-selected-block {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: flex-start !important; }

.patients-section .date {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 180px;
  display: inline-block; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.dct-inner {
  display: block !important; }

.profile-Details-top {
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .profile-Details-top h5 {
    margin: 0;
    padding: 0 0 5px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    letter-spacing: 0.535714px;
    color: #1F1E25; }
  .profile-Details-top h3.heading {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 375px; }
  .profile-Details-top p {
    margin: 0;
    padding: 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    letter-spacing: 0.5px;
    color: #2B3A62; }
  .profile-Details-top .value-with-bg-profile {
    background: #FFFFFF;
    box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
    border-radius: 16px;
    padding: 5px 15px;
    margin: 2px 10px 2px 0;
    display: inline-flex; }
  .profile-Details-top .tag-list {
    width: 560px;
    max-height: 100px;
    overflow: overlay;
    margin-right: 30px; }

.chart-header {
  padding: 0 0 40px 0; }
  .chart-header h3 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.714286px;
    color: #1F1E25;
    padding: 0;
    margin: 0 0 10px 0; }
  .chart-header p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    color: #2B3A62;
    letter-spacing: 0.535714px;
    padding: 0;
    margin: 0; }

.chart-main {
  margin: 0 0 120px 0; }
  .chart-main .chart-content {
    display: flex; }
    .chart-main .chart-content .chart-content-values .chart-content-values-item-list li {
      display: inline-flex;
      padding: 0 80px 20px 0; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .chart-content-value-flag {
        height: 16px;
        width: 16px;
        border-radius: 50%;
        display: block;
        margin: 0 10px 0 0; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .bluecolor {
        background: #58BDFF; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .redcolor {
        background: #FF4560; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .greencolor {
        background: #00E396; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .yellewcolor {
        background: #FEB019; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .magcolor {
        background: #a738fe; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .chart-content-values-items h4 {
        padding: 0 0 8px 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .chart-main .chart-content .chart-content-values .chart-content-values-item-list li .chart-content-values-items p {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #2B3A62; }

.chart-header-detailed h3 {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 20px;
  line-height: 23px;
  letter-spacing: 0.714286px;
  color: #1F1E25;
  padding: 0;
  margin: 0 0 10px 0; }

.chart-header-detailed p {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 15px;
  line-height: 24px;
  color: #2B3A62;
  letter-spacing: 0.535714px;
  padding: 0;
  margin: 0; }

.baseColor {
  color: #00c8fe; }

.main-pannel-connected-dct .pannel-connected-dct-text {
  padding: 0 0 0 10px; }

.main-pannel-connected-dct .dctName {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 270px;
  display: block; }

.main-pannel-connected-dct .accordionPannel {
  padding: 10px 0 10px 0; }

.patients-section .date {
  font-size: 12px;
  padding: 0 20px 0 0;
  width: 180px;
  display: inline-block;
  padding: 15px 50px 0px 0px; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .red-tag {
  background: #f6e2f8;
  color: #af4fba;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.baseColor {
  color: #00c8fe; }

.MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
  border-bottom: 1px solid #F0F2F8;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  /* identical to box height */
  letter-spacing: 0.535714px;
  color: #0091F1; }

.flag {
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0; }

.conversation-Report-main .conversation-Report-main-header {
  display: flex;
  align-items: center;
  justify-content: space-between; }
  .conversation-Report-main .conversation-Report-main-header .conversation-Report-main-header-info h4 {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 20px;
    line-height: 23px;
    letter-spacing: 0.714286px;
    color: #1F1E25;
    padding: 0 0 10px 0;
    margin: 0; }
  .conversation-Report-main .conversation-Report-main-header .conversation-Report-main-header-info p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 24px;
    padding: 0;
    margin: 0;
    letter-spacing: 0.535714px;
    color: #2B3A62; }
  .conversation-Report-main .conversation-Report-main-header .conversation-Report-main-header-tag {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    .conversation-Report-main .conversation-Report-main-header .conversation-Report-main-header-tag .conversation-assign-shape {
      width: 24px;
      height: 6px;
      background: #4facfe !important; }
    .conversation-Report-main .conversation-Report-main-header .conversation-Report-main-header-tag p {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 24px;
      padding: 0 0 0 9px;
      letter-spacing: 0.5px; }

.main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center;
  text-transform: capitalize !important; }

.conversation-Report-Period-Select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  width: 60px !important; }

.Period-Filter {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  align-items: baseline;
  width: 200px !important; }

.filtered-values {
  float: right; }
  .filtered-values .MuiPaper-elevation1 {
    background-color: transparent;
    padding: 20px 5px 0 5px;
    box-shadow: none; }

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px; }
  .filter-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .filter-paper-root .global-search-icon-btn {
    padding: 10px; }

.conversation-status-select {
  padding-left: 0px !important;
  align-content: flex-start;
  justify-content: flex-start;
  justify-self: flex-start;
  width: 400px !important; }

.table-actions .table-action-filter-results {
  position: relative;
  display: flex;
  align-items: center; }
  .table-actions .table-action-filter-results .filtered-values .single-edit-div {
    margin-bottom: 0; }
    .table-actions .table-action-filter-results .filtered-values .single-edit-div .conversation-status-select {
      margin-right: 10px;
      width: 190px !important; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap !important;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px !important; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62; }

.content-block-main {
  display: flex;
  height: 100vh;
  margin: 0 0 50px 0; }
  .content-block-main .content-block-8blocks {
    max-width: 500px;
    width: 500px;
    height: inherit;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden; }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 24px 0 0;
        border: none;
        outline: none; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
  .content-block-main .content-block-selected-block {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: flex-start !important; }

.patients-section .date {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 180px;
  display: inline-block; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.patients-section{
.date{
    font-size:12px;
    padding:0 20px 0 0;
}
.green-tag{
    background:#EFF8E2;
    color:#8CBA4F;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
.red-tag{
    background:#F2BCC3;
    color:#E98B96;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
}

.baseColor {
    color: $light-blue2;
}


.flag{
    height: 17px;
    width: 17px;
    background: #0EB600;
    display: block;
    float: left;
    border-radius: 50%;
    margin: 0 6px 0 0;
}

.patients-section {

.date {
    font-size: 12px;
    padding: 0 20px 0 0;
}

.map-btn-wrap {
    margin: 25px auto 0;
    display: flex;
}

.rx-heading {
    margin-bottom: 0;
}

.patients-section {

}

.date {
    font-size: 12px;
    padding: 0 20px 0 0;
}

.green-tag {
    background: #EFF8E2;
    color: #8CBA4F;
    border-radius: 20px;
    padding: 0 10px 0 10px;
    font-size: 14px;
}

.red-tag {
    background: #F2BCC3;
    color: #E98B96;
    border-radius: 20px;
    padding: 0 10px 0 10px;
    font-size: 14px;
}

.baseColor {
    color: $light-blue2;
}


.flag {
    height: 17px;
    width: 17px;
    background: #0EB600;
    display: block;
    float: left;
    border-radius: 50%;
    margin: 0 6px 0 0;
}

.field-wrapper {
    margin-bottom: 15px !important;
}

}

.patients-section{
.date{
    font-size:12px;
    padding:0 20px 0 0;
}
.green-tag{
    background:#EFF8E2;
    color:#8CBA4F;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
.red-tag{
    background:#F2BCC3;
    color:#E98B96;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
}

.baseColor {
    color: $light-blue2;
}


.flag{
    height: 17px;
    width: 17px;
    background: #0EB600;
    display: block;
    float: left;
    border-radius: 50%;
    margin: 12px 6px 0 0;
}

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.patients-section .date {
  font-size: 12px;
  padding: 0 20px 0 0; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.baseColor {
  color: #00c8fe;
  cursor: pointer; }

.flag {
  height: 17px;
  width: 17px;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 15px 6px 0 0; }

.dct-details-top-content-block {
  padding: 0px 42px 0px 0px !important; }
  .dct-details-top-content-block .dct-details-top-content-main-heading.heading {
    text-transform: capitalize !important;
    width: 375px !important;
    white-space: normal !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(1) .legend-icon {
  height: 5px;
  width: 12px;
  border-radius: 50%;
  margin: 0 20px 0  0;
  fill: #A7DB63 !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(1) .legend-label {
  fill: #A7DB63 !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(2) .legend-icon {
  height: 5px;
  width: 12px;
  border-radius: 50%;
  margin: 0 20px 0  0;
  fill: #E05C6E !important; }

.line-chart .wrapper .legend-wrapper .legend-item:nth-child(2) .legend-label {
  fill: #E05C6E !important; }

.main-login-container {
  width: 40%; }
  .main-login-container .login-header {
    font-size: 19pt;
    text-transform: uppercase; }
  .main-login-container .main-form {
    width: 100%;
    padding: 1px 30px; }
    .main-login-container .main-form .btn-right {
      float: right; }

.check-forgot {
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  margin-bottom: 15px; }
  .check-forgot .links {
    margin-top: 15px;
    color: #25345c; }
  .check-forgot .MuiCheckbox-colorPrimary-64.MuiCheckbox-checked-61 {
    color: #25345c; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.main-body-schedule {
  padding: 0 96px;
  max-width: 1600px;
  margin: 0 auto; }
  .main-body-schedule .dct-check.Mui-checked {
    color: #0091f1; }
  .main-body-schedule .days {
    margin-top: 30px !important; }
  .main-body-schedule .custom-btn {
    margin: 13px; }
  .main-body-schedule .appointments {
    display: inline-block;
    width: 100%;
    clear: both;
    margin: 50px 0; }
    .main-body-schedule .appointments .text-align {
      text-align: right; }
  .main-body-schedule .fade {
    color: #848488; }

/** Custom Styling */

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.MuiFormControl-root {
  width: 100%; }

.customMuiGridRoot > .MuiButtonBase-root {
  max-width: 100%;
  margin-top: 5px !important;
  margin-right: 2px !important;
  min-width: auto; }

.modal-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline;
  margin: 0 0 0 10px; }

.main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.modal-main h2 {
  padding: 0 !important; }

.modal-main h3 {
  padding: 15px 0 5px 0 !important;
  margin: 0 !important; }

.modal-main .field-wrapper {
  padding: 0; }

.modal-main .modal-header .tab-main-wrapper-tabs .MuiTabs-flexContainer .MuiTab-textColorInherit.Mui-selected {
  border-bottom: none !important; }

.modal-sections-main {
  display: flex; }
  .modal-sections-main .importance-level-main {
    padding: 0 15px 0 15px; }
    .modal-sections-main .importance-level-main .importance-level {
      overflow: hidden; }
      .modal-sections-main .importance-level-main .importance-level .field-wrapper {
        padding: 0;
        float: left; }
        .modal-sections-main .importance-level-main .importance-level .field-wrapper .MuiFormControlLabel-labelPlacementStart {
          padding: 0;
          margin: 0; }
    .modal-sections-main .importance-level-main .field-wrapper {
      padding: 0; }
      .modal-sections-main .importance-level-main .field-wrapper .MuiFormControlLabel-labelPlacementStart {
        padding: 0;
        margin: 0; }
  .modal-sections-main .second-section {
    height: 150px;
    overflow-y: scroll;
    padding: 0 15px 0 15px; }
    .modal-sections-main .second-section .field-wrapper {
      padding: 0; }
      .modal-sections-main .second-section .field-wrapper .MuiFormControlLabel-labelPlacementStart {
        padding: 0;
        margin: 0; }
  .modal-sections-main .require-notes {
    display: flex;
    justify-content: space-between; }

.MuiFormControl-root {
  width: 100%; }

.modal-wrapper {
  width: -webkit-max-content;
  width: -moz-max-content;
  width: max-content;
  display: inline;
  margin: 0 0 0 10px; }

.modal-main .field-wrapper {
  margin-bottom: 24px; }

.capitalize {
  text-transform: capitalize; }

.form-wrapper {
  display: flex; }
  .form-wrapper input {
    width: 180px !important; }
  .form-wrapper .MuiFormControl-root {
    max-width: 50px !important; }
  .form-wrapper .high-end-low-end-lable {
    width: 220px !important;
    line-height: 34px; }
  .form-wrapper .high-end-low-end {
    max-width: 50px !important; }

.controls-main {
  display: flex; }
  .controls-main .custom-btn {
    display: block; }
  .controls-main .controls-left {
    padding: 5px;
    width: 50%; }
  .controls-main .controls-right {
    padding: 5px;
    width: 50%; }

.draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.master-schedule-modal-content {
  min-width: 900px; }
  .master-schedule-modal-content .elements-controls-main {
    flex-direction: column; }
    .master-schedule-modal-content .elements-controls-main .elements-controls-left {
      width: 100%;
      display: flex;
      flex-wrap: wrap; }
      .master-schedule-modal-content .elements-controls-main .elements-controls-left > .field-wrapper {
        width: 36%;
        margin-right: 16px; }
    .master-schedule-modal-content .elements-controls-main .elements-details .rx-heading {
      margin-bottom: 5px;
      font-size: 16px; }
    .master-schedule-modal-content .elements-controls-main .elements-details .element-fields {
      display: flex;
      flex-wrap: wrap; }
      .master-schedule-modal-content .elements-controls-main .elements-details .element-fields > .field-wrapper {
        width: 28%;
        margin-right: 16px; }
        .master-schedule-modal-content .elements-controls-main .elements-details .element-fields > .field-wrapper .MuiInput-root {
          width: 100%; }
        .master-schedule-modal-content .elements-controls-main .elements-details .element-fields > .field-wrapper .MuiInputLabel-formControl {
          white-space: nowrap;
          width: 94%;
          text-overflow: ellipsis;
          overflow: hidden; }
  .master-schedule-modal-content .map-to-from-wrap {
    display: block;
    width: 100%; }
  .master-schedule-modal-content .map-to-from {
    display: flex; }
    .master-schedule-modal-content .map-to-from > .MuiFormControl-root {
      width: 100%;
      margin-right: 16px; }
    .master-schedule-modal-content .map-to-from .map-to-from.w-control {
      width: 21%;
      display: inline-block;
      margin-bottom: 10px;
      margin-right: 35px; }
  .master-schedule-modal-content .map-btn-wrap {
    display: flex;
    margin: 16px 0; }

.accordion-wrapper {
  margin: 24px 0; }

.accordion-content-wrap {
  width: 100%; }

.del-icon {
  margin-top: 16px; }

.patients-section{
.date{
    font-size:12px;
    padding:0 20px 0 0;
}
.green-tag{
    background:#EFF8E2;
    color:#8CBA4F;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
.red-tag{
    background:#F2BCC3;
    color:#E98B96;
    border-radius: 20px;
    padding:0 10px 0 10px;
    font-size:14px;
}
}

.baseColor {
    color: $light-blue2;
}


.flag{
    height: 17px;
    width: 17px;
    background: #0EB600;
    display: block;
    float: left;
    border-radius: 50%;
    margin: 0 6px 0 0;
}

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

.clickable-link {
  font-size: 14px;
  color: #4facfe !important;
  cursor: pointer; }

::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(170, 170, 170, 0.15); }

::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
  cursor: pointer; }

::-webkit-scrollbar-thumb:hover,
.modal::-webkit-scrollbar-thumb:hover {
  background: #999999; }

.title-header-main .title-header {
  height: auto !important; }

.main-container-appointments {
  margin: 0 auto; }
  .main-container-appointments .main-body-appointments {
    padding: 15px 96px;
    max-width: 1600px;
    margin: 0 auto; }
  .main-container-appointments .table-mid {
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    transition: all .4s ease-in-out 0s;
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04); }
  .main-container-appointments .table-component-main .rdt_Table .rdt_TableHeadRow {
    min-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: transparent !important; }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
      font-weight: 600;
      font-size: 13px;
      color: #252529; }
  .main-container-appointments .table-component-main .rdt_Table .sc-bBHwJV, .main-container-appointments .table-component-main .rdt_Table .sc-dlVyqM {
    height: calc(100vh - 317px); }
  .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody {
    height: calc(100vh - 373px);
    overflow: overlay;
    margin: 10px 0px; }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
      background-color: rgba(240, 248, 255, 0.5); }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody .rdt_TableRow rdt_TableCell {
      font-weight: 400;
      color: #333; }
  .main-container-appointments .table-component-main .rdt_Pagination {
    min-height: 30px;
    padding-top: 10px; }
    .main-container-appointments .table-component-main .rdt_Pagination .kFZtQx {
      width: 24px;
      height: 24px;
      padding: 0px; }
  .main-container-appointments .dropdown-select .MuiInput-root {
    min-width: 100px; }
    .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-select {
      color: #252529 !important;
      font-weight: 400;
      font-size: 14px; }
      .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-select:focus {
        background-color: transparent; }
    .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-icon {
      color: #252529;
      right: -5px; }
    .main-container-appointments .dropdown-select .MuiInput-root:before {
      border-bottom-color: #252529; }
    .main-container-appointments .dropdown-select .MuiInput-root:after {
      border-bottom: 1px solid #133590; }
    .main-container-appointments .dropdown-select .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #00c2ff; }
    .main-container-appointments .dropdown-select .MuiInput-root:hover .MuiSelect-icon,
    .main-container-appointments .dropdown-select .MuiInput-root:hover .MuiSelect-select {
      color: #00c2ff !important; }

.MuiPopover-paper {
  box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04) !important; }
  .MuiPopover-paper .MuiMenu-list .MuiListItem-button {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #252529 !important; }
    .MuiPopover-paper .MuiMenu-list .MuiListItem-button:hover {
      background-color: #00c2ff;
      color: #fff !important; }
  .MuiPopover-paper .MuiMenu-list .Mui-selected {
    background-color: #00c2ff;
    color: #fff !important; }

.dct-detail-page-header-main {
  height: 250px;
  left: 0;
  top: 480px;
  background: linear-gradient(1deg, #FFFFFF 0%, #F1F9FF 100%); }
  .dct-detail-page-header-main .detail-page-header {
    max-width: 1600px;
    padding: 0 96px 0 96px;
    margin: 0 auto;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 265px; }
    .dct-detail-page-header-main .detail-page-header .dct-details-top-content {
      display: flex;
      align-items: center;
      justify-content: space-between; }
      .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block {
        padding: 0 60px 0 0; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: bold;
          font-size: 26px;
          line-height: 40px;
          color: #2B3A62;
          letter-spacing: 0.7px;
          padding: 0;
          margin: 0 0 8px 0;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          width: 460px; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-main-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          padding: 0;
          margin: 0;
          color: #2B3A62; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-heading {
          font-family: Roboto;
          font-style: normal;
          font-weight: 500;
          font-size: 15px;
          line-height: 18px;
          padding: 0;
          margin: 20px 0 16px 0;
          letter-spacing: 0.535714px;
          color: #1F1E25; }
        .dct-detail-page-header-main .detail-page-header .dct-details-top-content .dct-details-top-content-block .dct-details-top-content-text {
          font-family: Roboto;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 16px;
          letter-spacing: 0.5px;
          color: #2B3A62;
          min-height: 24px; }

.content-block-main {
  display: flex;
  height: 100vh;
  margin: 0 0 50px 0; }
  .content-block-main .content-block-8blocks {
    max-width: 500px;
    width: 500px;
    height: inherit;
    border-right: 1px solid rgba(0, 0, 0, 0.12);
    overflow-y: scroll;
    overflow-x: hidden; }
    .content-block-main .content-block-8blocks .content-block-8blocks-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .dct-inner {
        display: block !important; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        margin: 26px 0 22px 24px;
        letter-spacing: 0.538462px;
        padding: 0;
        color: #2B3A62; }
      .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper {
        height: 32px;
        width: 32px;
        border-radius: 50%;
        background: #0091F1;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        margin: 0 24px 0 0;
        border: none;
        outline: none; }
        .content-block-main .content-block-8blocks .content-block-8blocks-header .icon-wrapper svg {
          margin: 0 auto; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item {
      display: flex;
      padding: 33px 0 25px 0;
      border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
    .content-block-main .content-block-8blocks .content-block-8blocks-item-dct {
      display: flex;
      padding: 33px 0 25px 0;
      border: 1px solid #4B94C8;
      position: relative; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .dct-indicator {
        background: #4B94C8;
        position: absolute;
        left: 80px;
        top: -12px;
        z-index: 222;
        width: 72px;
        height: 24px;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 13px;
        line-height: 23px;
        letter-spacing: 0.433333px;
        border-radius: 15px;
        color: #FFFFFF;
        text-align: center; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header .MuiIconButton-edgeEnd {
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header .MuiAccordionSummary-content {
        padding: 0;
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-dct-header ::before {
        display: none;
        box-shadow: none !important; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .MuiAccordion-root:before {
        display: none; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .MuiPaper-elevation1 {
        box-shadow: none !important;
        background: none;
        width: inherit;
        overflow: hidden;
        padding: 0px;
        margin: 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-icon {
        display: flex;
        horiz-align: center;
        justify-content: space-between;
        width: 68px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-icon svg {
          margin: 0 auto; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content p {
        font-family: "Roboto", sans-serif;
        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 18px;
        letter-spacing: 0.535714px;
        color: #25345C;
        padding: 0;
        margin: 0 0 16px 0; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text {
        display: flex;
        padding: 0 0 8px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text h4 {
          padding: 0;
          margin: 0;
          width: 292px;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 21px;
          letter-spacing: 0.642857px;
          color: #1F1E25; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-text span {
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: 900;
          font-size: 13px;
          line-height: 15px;
          text-align: right;
          letter-spacing: 0.464286px;
          width: 46px;
          padding: 0 10px 0 22px;
          color: #8F9EAE;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis; }
      .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer {
        display: flex;
        horiz-align: center;
        justify-content: space-between; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .MuiSvgIcon-root {
          font-size: 20px; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .link-icon {
          transform: rotate(140deg); }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .block-icon {
          padding: 0 5px 0; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer h5 {
          padding: 0;
          margin: 0;
          font-family: "Roboto", sans-serif;
          font-style: normal;
          font-weight: normal;
          font-size: 14px;
          line-height: 24px;
          letter-spacing: 0.5px;
          color: #515D7D;
          mix-blend-mode: normal;
          opacity: 0.75; }
        .content-block-main .content-block-8blocks .content-block-8blocks-item-dct .content-block-8blocks-item-content .content-block-8blocks-item-content-footer .icons {
          padding: 0 4px 0 0; }
  .content-block-main .content-block-selected-block {
    overflow-y: auto;
    overflow-x: hidden;
    padding: 94px 20px 0px 20px; }
    .content-block-main .content-block-selected-block .content-block-selected-block-header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 0 0 24px 0; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header h3 {
        padding: 0;
        margin: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 20px;
        line-height: 40px;
        letter-spacing: 0.538462px;
        color: #2B3A62; }
      .content-block-main .content-block-selected-block .content-block-selected-block-header .icon-wrapper i {
        padding: 0 0 0 24px; }
    .content-block-main .content-block-selected-block .MuiTab-wrapper {
      align-items: flex-start !important; }

.patients-section .date {
  font-family: "Roboto", sans-serif;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 24px;
  /* identical to box height, or 171% */
  letter-spacing: 0.5px;
  color: #515D7D;
  width: 180px;
  display: inline-block; }

.patients-section .main-tag {
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px;
  width: 123px;
  display: block;
  float: right;
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  line-height: 26px;
  letter-spacing: 0.5px;
  text-align: center; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F; }

.patients-section .orange-tag {
  background: rgba(255, 143, 2, 0.1);
  color: #FF8F02; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96; }

.dct-inner {
  display: block !important; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.values {
  float: left;
  background-color: #f2f9ff;
  border-radius: 25px !important;
  color: #6b6c6f;
  font-size: 15px;
  font-style: normal;
  padding: 10px; }

.button {
  float: left;
  background-color: #f2f9ff !important;
  border-radius: 2px !important;
  border-left-width: thin !important;
  border-color: #0079ff !important;
  color: #0079ff !important;
  margin: 5px !important; }
  .button:hover {
    background-color: #ffffff !important;
    color: black !important; }

.appearsInText {
  color: #00c8fe !important;
  font-size: 15px;
  font-weight: 600; }

.MuiButton-textPrimary {
  color: #0091f1 !important; }

.create-service-container {
  padding: 30px; }
  .create-service-container .create-service-inner {
    max-width: 600px;
    margin: 0 auto; }
  .create-service-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-service-container .single-edit-div .field-name {
      width: 35%; }
    .create-service-container .single-edit-div .edit-input {
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px #E6E9EF solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-service-container .single-edit-div .profileElementType-multiselect {
      padding-left: 0px !important;
      align-content: flex-start;
      justify-content: flex-start;
      justify-self: flex-start;
      width: 400px !important; }
    .create-service-container .single-edit-div .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .MuiInput-underline:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect {
      padding-left: 0px !important;
      width: 400px !important; }
    .create-service-container .single-edit-div .menuItem {
      padding-left: 0px !important;
      display: inline-flex;
      justify-content: flex-start; }

.root {
  display: inline-flex;
  justify-content: flex-start;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  border: 1px #E6E9EF solid;
  padding: 4px;
  box-sizing: content-box;
  width: 400px; }

.chip {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.list {
  list-style-type: none; }

.save-btn-txt {
  color: white; }

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px; }
  .search-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .search-paper-root .global-search-icon-btn {
    padding: 10px; }

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px; }
  .filter-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .filter-paper-root .global-search-icon-btn {
    padding: 10px; }

.search-main .search-input {
  border: none !important;
  min-width: 500px !important; }

.search-main .search-paper-root {
  box-shadow: none;
  border-radius: 0; }
  .search-main .search-paper-root input {
    font-size: 12px;
    width: 234px; }

.MuiSelect-select.MuiSelect-select {
  color: #0091f1 !important; }

.content-main .filtered-values {
  float: right; }
  .content-main .filtered-values .MuiPaper-elevation1 {
    background-color: transparent;
    padding: 20px 5px 0 5px;
    box-shadow: none; }

@media screen and (max-width: 1367px) {
  .bMYmCz {
    max-width: 190px !important; } }

.dJshAA div:first-child {
  white-space: break-spaces !important;
  overflow: hidden;
  text-overflow: ellipsis; }

.value-with-bg {
  margin: 2px; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

.values {
  float: left;
  background-color: #f2f9ff;
  border-radius: 25px !important;
  color: #6b6c6f;
  font-size: 15px;
  font-style: normal;
  padding: 10px; }

.button {
  float: left;
  background-color: #f2f9ff !important;
  border-radius: 2px !important;
  border-left-width: thin !important;
  border-color: #0079ff !important;
  color: #0079ff !important;
  margin: 5px !important; }
  .button:hover {
    background-color: #ffffff !important;
    color: black !important; }

.appearsInText {
  color: #00c8fe !important;
  font-size: 15px;
  font-weight: 600; }

.MuiButton-textPrimary {
  color: #0091f1 !important; }

.create-service-container {
  padding: 30px; }
  .create-service-container .create-service-inner {
    max-width: 600px;
    margin: 0 auto; }
  .create-service-container .single-edit-div {
    padding: 0;
    margin-bottom: 10px; }
    .create-service-container .single-edit-div .field-name {
      width: 35%; }
    .create-service-container .single-edit-div .edit-input {
      margin-top: 2px !important;
      margin-bottom: 0 !important;
      height: 30px;
      border: 1px #E6E9EF solid;
      padding: 8px;
      font-size: 16px;
      font-weight: 400;
      font-style: normal;
      border-radius: 4px;
      font-family: "Roboto", sans-serif;
      letter-spacing: 0.5px;
      box-sizing: content-box; }
    .create-service-container .single-edit-div .profileElementType-multiselect {
      padding-left: 0px !important;
      align-content: flex-start;
      justify-content: flex-start;
      justify-self: flex-start;
      width: 400px !important; }
    .create-service-container .single-edit-div .MuiInput-underline:hover:not(.Mui-disabled):before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .MuiInput-underline:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect:before {
      border-bottom: none !important; }
    .create-service-container .single-edit-div .profileElementMethod-multiselect {
      padding-left: 0px !important;
      width: 400px !important; }
    .create-service-container .single-edit-div .menuItem {
      padding-left: 0px !important;
      display: inline-flex;
      justify-content: flex-start; }

.root {
  display: inline-flex;
  justify-content: flex-start;
  overflow-wrap: anywhere;
  flex-wrap: wrap;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  border: 1px #E6E9EF solid;
  padding: 4px;
  box-sizing: content-box;
  width: 400px; }

.chip {
  margin: 5px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  border-radius: 4px;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.list {
  list-style-type: none; }

.save-btn-txt {
  color: white; }

.search-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 400px; }
  .search-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .search-paper-root .global-search-icon-btn {
    padding: 10px; }

.filter-paper-root {
  padding: 2px 4px;
  display: flex;
  margin-top: 0px;
  margin-bottom: 20px;
  align-items: center;
  width: 500px; }
  .filter-paper-root .global-search-input {
    flex: 1 1;
    padding-left: 10px; }
  .filter-paper-root .global-search-icon-btn {
    padding: 10px; }

.search-main .search-input {
  border: none !important;
  min-width: 500px !important; }

.search-main .search-paper-root {
  box-shadow: none;
  border-radius: 0; }
  .search-main .search-paper-root input {
    font-size: 12px;
    width: 234px; }

.MuiSelect-select.MuiSelect-select {
  color: #0091f1 !important; }

.content-main .filtered-values {
  float: right; }
  .content-main .filtered-values .MuiPaper-elevation1 {
    background-color: transparent;
    padding: 20px 5px 0 5px;
    box-shadow: none; }

@media screen and (max-width: 1367px) {
  .bMYmCz {
    max-width: 190px !important; } }

.dJshAA div:first-child {
  white-space: break-spaces !important;
  overflow: hidden;
  text-overflow: ellipsis; }

.value-with-bg {
  margin: 2px; }

.value-with-bg {
  margin: 2px; }

.patients-section .date {
  font-size: 12px;
  padding: 0 20px 0 0;
  width: 180px;
  display: inline-block;
  padding: 15px 50px 0px 0px; }

.patients-section .green-tag {
  background: #EFF8E2;
  color: #8CBA4F;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.patients-section .red-tag {
  background: #F2BCC3;
  color: #E98B96;
  border-radius: 20px;
  padding: 0 10px 0 10px;
  font-size: 14px; }

.baseColor {
  color: #00c8fe; }

.flag {
  height: 17px;
  width: 17px;
  background: #0EB600;
  display: block;
  float: left;
  border-radius: 50%;
  margin: 0 6px 0 0; }

.wd-5 {
  width: 5%; }

.wd-10 {
  width: 10%; }

.wd-15 {
  width: 15%; }

.wd-20 {
  width: 20%; }

.wd-25 {
  width: 25%; }

.wd-30 {
  width: 30%; }

.wd-33 {
  width: 33.33%; }

.wd-35 {
  width: 35%; }

.wd-40 {
  width: 40%; }

.wd-45 {
  width: 45%; }

.wd-50 {
  width: 50%; }

.wd-55 {
  width: 55%; }

.wd-60 {
  width: 60%; }

.wd-65 {
  width: 65%; }

.wd-70 {
  width: 70%; }

.wd-75 {
  width: 75%; }

.wd-80 {
  width: 80%; }

.wd-85 {
  width: 85%; }

.wd-90 {
  width: 90%; }

.wd-95 {
  width: 95%; }

.wd-100 {
  width: 100%; }

.m-t-5 {
  margin-top: 5px; }

.m-b-5 {
  margin-bottom: 5px; }

.m-l-5 {
  margin-left: 5px; }

.m-r-5 {
  margin-right: 5px; }

.m-t-10 {
  margin-top: 10px; }

.m-b-10 {
  margin-bottom: 10px; }

.m-l-10 {
  margin-left: 10px; }

.m-r-10 {
  margin-right: 10px; }

.m-t-15 {
  margin-top: 15px; }

.m-b-15 {
  margin-bottom: 15px; }

.m-l-15 {
  margin-left: 15px; }

.m-r-15 {
  margin-right: 15px; }

.m-t-20 {
  margin-top: 20px; }

.m-b-20 {
  margin-bottom: 20px; }

.m-l-20 {
  margin-left: 20px; }

.m-r-20 {
  margin-right: 20px; }

.p-t-5 {
  padding-top: 5px; }

.p-b-5 {
  padding-bottom: 5px; }

.p-l-5 {
  padding-left: 5px; }

.p-r-5 {
  padding-right: 5px; }

.p-t-10 {
  padding-top: 10px; }

.p-b-10 {
  padding-bottom: 10px; }

.p-l-10 {
  padding-left: 10px; }

.p-r-10 {
  padding-right: 10px; }

.p-t-15 {
  padding-top: 15px; }

.p-b-15 {
  padding-bottom: 15px; }

.p-l-15 {
  padding-left: 15px; }

.p-r-15 {
  padding-right: 15px; }

.p-t-20 {
  padding-top: 20px; }

.p-b-20 {
  padding-bottom: 20px; }

.p-l-20 {
  padding-left: 20px; }

.p-r-20 {
  padding-right: 20px; }

.d-flex {
  display: flex; }

.align-items-start {
  align-items: start; }

.align-items-center {
  align-items: center; }

.align-items-end {
  align-items: end; }

.justify-content-start {
  justify-content: start; }

.justify-content-center {
  justify-content: center; }

.justify-content-end {
  justify-content: end; }

.flex-direction-row {
  flex-direction: row; }

.flex-direction-column {
  flex-direction: column; }

.shadow-none {
  box-shadow: none !important; }

.radius-none {
  border-radius: 0 !important; }

.main-container {
  height: 100vh;
  max-width: 100%;
  margin: 0 auto; }
  .main-container .main-body {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-grow: 1; }
    .main-container .main-body .main-login-container {
      padding: 30px;
      margin-top: 24px;
      border: 2px #E6E9EF solid;
      max-width: 600px; }
      .main-container .main-body .main-login-container .main-login {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-title {
          display: flex;
          flex-direction: column;
          align-items: center; }
        .main-container .main-body .main-login-container .main-login .main-form .main-input-div {
          display: flex;
          flex-direction: column;
          justify-content: flex-start;
          margin-top: 10px; }

.main-btn {
  display: flex;
  justify-content: center; }
  .main-btn .main-btn-sty {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #25345c;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .main-btn-sty:hover {
      background-color: #25345c; }
    .main-btn .main-btn-sty .loginbtntxt {
      color: white; }
  .main-btn .secondary-btn {
    margin-bottom: 20px;
    margin-top: 20px;
    background-color: #D1D1D1;
    width: 150px;
    height: 50px;
    margin-left: 15px; }
    .main-btn .secondary-btn .secbtntxt {
      color: #737373; }

.container {
  display: flex;
  flex-direction: column;
  margin: 20px 20px; }
  .container .bar-main {
    border: 0.5px #E6E9EF solid;
    padding: 30px;
    box-shadow: 2px 4px #F5F5F5;
    border-radius: 8px; }

.main-head-text {
  text-align: center;
  font-size: 18px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-text-large {
  text-align: center;
  font-size: 25px;
  color: #252529;
  margin-bottom: 5px;
  font-weight: 800; }

.dark-medium-text {
  font-size: 14px;
  font-weight: 800;
  color: #3e3f42; }

.dark-text-small {
  font-size: 16px !important;
  font-weight: 700 !important;
  color: #252529 !important;
  padding-top: 5px;
  padding-bottom: 5px; }

.light-text-large {
  text-align: center;
  font-size: 16px;
  color: #9ea0a5;
  margin-bottom: 5px;
  font-weight: 500; }

.light-text-medium {
  font-size: 14px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.light-text-small {
  font-size: 12px !important;
  font-weight: 600 !important;
  color: #9ea0a5; }

.blue-bg {
  background: #F1F9FF !important; }

.blue-text {
  font-size: 14px;
  font-weight: 700;
  color: #4facfe !important; }

.blue-text-tables {
  font-family: Roboto;
  font-style: normal;
  font-weight: normal;
  font-size: 14px;
  line-height: 16px;
  letter-spacing: 0.5px;
  color: #0091F1 !important; }

.small-green-txt {
  font-size: 14px;
  color: green;
  padding-top: 5px;
  font-weight: 800;
  padding-right: 5px; }

.large-green-txt {
  color: green;
  padding-top: 20px;
  font-size: 24px;
  padding-top: 20px;
  font-weight: bold; }

.dot1 {
  height: 10px;
  width: 10px;
  background-color: #4facfe;
  border-radius: 50%;
  display: inline-block;
  margin-right: 5px; }

.dot2 {
  background-color: #25345c; }

.MuiSelect-select:focus {
  border-radius: 0;
  background-color: transparent; }

.titletxt {
  color: #3e3f42;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center; }
  .titletxt span {
    color: #9ea0a5;
    font-size: 20px; }

.subtitle {
  display: flex;
  flex-wrap: wrap;
  width: 80%;
  margin-top: 10px;
  text-align: center;
  font-size: 12px;
  font-style: normal;
  font-family: "Roboto", sans-serif; }

.txt-input {
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  border: 1px #9ea0a5 solid;
  padding: 8px;
  font-size: 16px;
  font-weight: 400;
  font-style: normal;
  font-family: "Roboto", sans-serif;
  letter-spacing: 0.5px; }

.error-input {
  border: 1px red solid;
  width: 450px;
  background-color: white;
  margin-top: 2px !important;
  margin-bottom: 0 !important;
  height: 30px;
  padding: 8px; }

.txt-input:focus {
  outline: none;
  border: 1.5px #25345c solid; }

/* Change the white to any color ;) */
input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
  -webkit-box-shadow: 0 0 0 30px white inset !important; }

.labeltext {
  color: #6b6c6f;
  margin-left: 2px; }

.main-error-txt {
  margin: 5px 0 10px 2px !important;
  width: 450px; }
  .main-error-txt .error-txt {
    color: red;
    font-size: 12px;
    font-weight: 400;
    line-height: 1.5; }

.no-score-main {
  margin-top: 25px;
  display: flex;
  justify-content: center;
  align-items: center; }
  .no-score-main img {
    height: 25px;
    width: 25px;
    margin-right: 10px; }

.MuiAppBar-root {
  position: fixed !important; }

.Toastify .Toastify__toast--success {
  background-color: rgba(43, 184, 38, 0.9) !important; }
  .Toastify .Toastify__toast--success .Toastify__toast-body {
    color: #fff !important; }

.margin-bottom-5 {
  margin-bottom: 10px !important; }

.field-wrapper {
  padding: 0 0 30px 0;
  display: flex;
  justify-content: space-between; }
  .field-wrapper .dct-check.Mui-checked {
    color: #0091f1; }
  .field-wrapper .inline-control {
    margin-right: 10px; }
  .field-wrapper lable {
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 11px;
    letter-spacing: 1.5px;
    text-transform: uppercase;
    color: #6F7EA7; }

.firstField {
  margin-right: 10px !important; }

.range-color-box {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  font-size: 14px;
  margin-right: 15px;
  width: 135px; }
  .range-color-box .color-dot {
    width: 19px;
    height: 19px;
    border-radius: 10px;
    margin-right: 6px;
    flex-shrink: 0; }

.modal-main {
  position: relative;
  width: 552px;
  margin: 64px auto !important;
  background: white;
  padding: 30px;
  border-radius: 10px;
  outline: none;
  max-height: 430px;
  overflow: auto; }
  .modal-main .modal-close {
    position: absolute;
    right: 20px;
    min-width: 30px;
    z-index: 9999; }
  .modal-main h2 {
    padding: 0 0 50px 0;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 20px;
    line-height: 32px;
    letter-spacing: 0.555556px;
    color: #181819;
    margin: 0; }

.MuiTableRow-root .MuiTableCell-head {
  background: #FBFBFC;
  font-size: 12px; }

.blueColorText {
  color: #0091F1; }

.value-with-bg {
  background: #F1F9FF;
  color: #8B96AE;
  padding: 4px 6px 4px 6px;
  border-radius: 15px;
  margin: 0 4px 2px 0;
  display: inline-block; }

.TitleBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #F1F9FF; }

.title-header-main {
  background: #f1f9ffa6;
  mix-blend-mode: normal; }
  .title-header-main .title-header {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    background: #F1F9FF;
    max-width: 1600px;
    margin: 0 auto;
    padding: 0 96px 0 96px;
    height: 160px; }
    .title-header-main .title-header h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: bold;
      font-size: 26px;
      line-height: 40px;
      /* identical to box height, or 154% */
      letter-spacing: 0.7px;
      color: #22242A;
      width: 100%; }

.MuiBreadcrumbs-separator {
  display: none !important; }

.MuiLink-underlineHover {
  font-family: Roboto;
  font-style: normal;
  font-weight: 500;
  font-size: 15px;
  line-height: 18px;
  color: #0091F1 !important; }

li.MuiBreadcrumbs-li {
  line-height: 2px; }
  li.MuiBreadcrumbs-li .header-div {
    display: flex;
    align-items: center; }
    li.MuiBreadcrumbs-li .header-div a {
      padding: 0 10px 0 30px; }
  li.MuiBreadcrumbs-li a {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    color: #0091F1;
    padding: 0 20px; }
  li.MuiBreadcrumbs-li p {
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 15px;
    line-height: 18px;
    color: #25345C;
    padding-left: 20px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 430px; }

.custom-btn-disabled {
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.custom-btn {
  background: #0091F1 !important;
  border-radius: 4px !important;
  text-transform: capitalize !important;
  box-shadow: none !important;
  min-width: -webkit-max-content !important;
  min-width: -moz-max-content !important;
  min-width: max-content !important;
  color: white !important; }

.button-with-no-bg {
  background: none;
  border: none;
  outline: none; }

.button-with-no-bg-and-font {
  background: none;
  border: none;
  outline: none;
  text-transform: capitalize !important; }

.remove-data {
  padding: 26px 0 0 15px; }

.custom-btn-outlined-with-white-bg {
  background: #ffffff !important;
  border-radius: 4px;
  text-transform: capitalize !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  color: #0091f1 !important;
  margin-right: 15px !important;
  box-shadow: none !important; }

.custom-OutLined-btn {
  background: #F1F9FF !important;
  border: 1px solid rgba(0, 145, 241, 0.345498) !important;
  box-sizing: border-box !important;
  border-radius: 4px !important; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #0091F1 !important; }

.MuiRadio-root {
  color: #0091F1 !important; }

.MuiInputLabel-animated {
  color: #6F7EA7 !important;
  font-family: Roboto;
  font-style: normal !important;
  font-weight: normal !important;
  font-size: 15px !important;
  line-height: 16px !important;
  letter-spacing: 0.5px !important; }

.builder-modal-title {
  padding: 50px 0 50px 50px;
  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 20px;
  line-height: 32px;
  /* identical to box height, or 162% */
  letter-spacing: 0.555556px;
  color: #181819; }

.MuiAutocomplete-inputRoot[class*="MuiFilledInput-root"] {
  background-color: #fff !important; }

.MuiAutocomplete-tag {
  background: #F1F9FF !important;
  border: none !important; }

.MuiChip-deleteIcon {
  color: #0091F1 !important; }

.main-body-c .main-banner {
  display: flex;
  justify-content: center;
  background: #FFF5F0;
  color: #AE1824; }

.main-body-c .draft-banner {
  display: flex;
  justify-content: center;
  background: #f0f6ff;
  color: #1854ae; }

.main-body-c .content-main {
  max-width: 1600px;
  padding: 0 96px 20px 96px;
  margin: 0 auto; }
  .main-body-c .content-main .title-header-main {
    background: #f1f9ffa6;
    mix-blend-mode: normal; }
  .main-body-c .content-main .table-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 auto;
    height: 100px; }
    .main-body-c .content-main .table-actions > .MuiInputBase-root {
      min-width: 500px; }
    .main-body-c .content-main .table-actions h3 {
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 20px;
      line-height: 23px;
      letter-spacing: 0.714286px;
      padding: 0;
      margin: 0;
      color: #1F1E25; }
    .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input {
      border: none !important;
      min-width: 500px !important;
      font-family: Roboto;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 14px;
      letter-spacing: 0.5px; }
      .main-body-c .content-main .table-actions .MuiFormControl-root.MuiTextField-root.search-input input#input-with-icon-textfield {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 14px;
        color: #475885; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-underline:before {
      border: none !important; }
    .main-body-c .content-main .table-actions .table-action-filter-results .MuiInput-root {
      color: #475885;
      font-family: Roboto;
      font-style: normal;
      font-weight: 500;
      font-size: 14px;
      line-height: 21px;
      text-align: right;
      letter-spacing: 0.7px; }
  .main-body-c .content-main .listing-name {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 270px;
    display: block; }

.main-body-c .detail-page-header-main {
  background: linear-gradient(0deg, #FFFFFF 0%, #F1F9FF 100%); }
  .main-body-c .detail-page-header-main .detail-page-header {
    margin: 0 auto;
    padding: 12px 96px 0 96px;
    max-width: 1600px; }
    .main-body-c .detail-page-header-main .detail-page-header .details-top-section {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 200px;
      left: 0px;
      top: 480px; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 {
        font-family: Roboto;
        font-style: normal;
        font-weight: bold;
        font-size: 26px;
        line-height: 40px;
        letter-spacing: 0.7px;
        color: #2B3A62; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active-flag {
          height: 17px;
          width: 17px;
          background: rgba(182, 168, 177, 0.14);
          display: block;
          float: left;
          border-radius: 50%;
          margin: 10px 6px 0 0; }
        .main-body-c .detail-page-header-main .detail-page-header .details-top-section h3 .active {
          background: #0EB600; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section h5 {
        margin: 0;
        padding: 0 0 16px 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: 500;
        font-size: 15px;
        line-height: 18px;
        /* identical to box height */
        letter-spacing: 0.535714px;
        color: #1F1E25; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section p {
        margin: 0;
        padding: 0;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 14px;
        line-height: 16px;
        letter-spacing: 0.5px;
        color: #2B3A62; }
      .main-body-c .detail-page-header-main .detail-page-header .details-top-section .value-with-bg-profile {
        background: #FFFFFF;
        box-shadow: 0px 2px 4px rgba(150, 163, 198, 0.15);
        border-radius: 16px;
        padding: 5px 15px;
        margin: 0 10px 0 0; }

.chart-header-detailed {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1600px;
  margin: 0 auto;
  height: 160px; }
  .chart-header-detailed .chart-header-detailed-category p {
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 24px;
    /* identical to box height, or 171% */
    letter-spacing: 0.5px;
    color: #4facfe; }
    .chart-header-detailed .chart-header-detailed-category p span {
      height: 6px;
      width: 24px;
      border-radius: 0px;
      background: #4facfe;
      display: inline-block;
      margin: 0 16px 0 0; }

.view-OutLined-btn {
  margin-right: 16px !important;
  width: 40px !important;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid #0091f1 !important; }
  .view-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.edit-OutLined-btn {
  width: 40px;
  min-width: 40px !important;
  padding: 0 !important;
  height: 40px;
  border: 1px solid rgba(0, 145, 241, 0.35) !important;
  background-color: #f1f9ff !important; }
  .edit-OutLined-btn .MuiSvgIcon-root {
    font-size: 19px; }

.btn-wrapper {
  display: flex; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }
  .btn-wrapper .custom-btn {
    margin-right: 20px;
    border-radius: 4px !important;
    padding: 14px 24px;
    flex: 0 1 !important;
    min-width: 150px; }
    .btn-wrapper .custom-btn .MuiButton-label {
      font-size: 15px;
      font-family: "Roboto", sans-serif;
      line-height: 16px;
      letter-spacing: 0.75px;
      font-weight: 400; }

.padding-right-10 {
  padding: 0 10px 0 0; }

.table-component-main .rdt_TableHeader {
  display: none !important; }

.table-component-main .rdt_TableHeadRow {
  background: #f7f6f6 !important; }

.table-component-main .rdt_TableRow {
  padding: 10px 0 10px 0 !important; }

.buttons-Wrapper .edit-OutLined-btn {
  margin: 0 10px 0 0; }

.contains-col {
  color: #0091f1; }

.MuiMenu-paper {
  min-width: 300px !important; }
  .MuiMenu-paper .MuiButtonBase-root.MuiListItem-root.MuiListItem-gutters.MuiListItem-button {
    white-space: pre-line;
    max-width: 350px; }

.padding-left-20 {
  padding: 0 0 0 20px; }

.long-text-field {
  height: 80px;
  border: #e8e8e8 1px solid;
  padding: 5px; }

.eIKzDm {
  width: 80px !important; }

::-webkit-scrollbar,
.modal::-webkit-scrollbar {
  width: 6px;
  height: 6px; }

.clickable-link {
  font-size: 14px;
  color: #4facfe !important;
  cursor: pointer; }

::-webkit-scrollbar-track,
.modal::-webkit-scrollbar-track {
  border-radius: 5px;
  background: rgba(170, 170, 170, 0.15); }

::-webkit-scrollbar-thumb,
.modal::-webkit-scrollbar-thumb {
  background: #c1c1c1;
  border-radius: 5px;
  cursor: pointer; }

::-webkit-scrollbar-thumb:hover,
.modal::-webkit-scrollbar-thumb:hover {
  background: #999999; }

.title-header-main .title-header {
  height: auto !important; }

.main-container-appointments {
  margin: 0 auto; }
  .main-container-appointments .main-body-appointments {
    padding: 15px 96px;
    max-width: 1600px;
    margin: 0 auto; }
  .main-container-appointments .table-mid {
    padding: 15px;
    border-radius: 5px;
    background-color: #fff;
    transition: all .4s ease-in-out 0s;
    box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04); }
  .main-container-appointments .table-component-main .rdt_Table .rdt_TableHeadRow {
    min-height: 40px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12);
    border-top: 1px solid rgba(0, 0, 0, 0.12);
    background-color: transparent !important; }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableHeadRow .rdt_TableCol {
      font-weight: 600;
      font-size: 13px;
      color: #252529; }
  .main-container-appointments .table-component-main .rdt_Table .sc-bBHwJV, .main-container-appointments .table-component-main .rdt_Table .sc-dlVyqM {
    height: calc(100vh - 317px); }
  .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody {
    height: calc(100vh - 373px);
    overflow: overlay;
    margin: 10px 0px; }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody .rdt_TableRow:hover {
      background-color: rgba(240, 248, 255, 0.5); }
    .main-container-appointments .table-component-main .rdt_Table .rdt_TableBody .rdt_TableRow rdt_TableCell {
      font-weight: 400;
      color: #333; }
  .main-container-appointments .table-component-main .rdt_Pagination {
    min-height: 30px;
    padding-top: 10px; }
    .main-container-appointments .table-component-main .rdt_Pagination .kFZtQx {
      width: 24px;
      height: 24px;
      padding: 0px; }
  .main-container-appointments .dropdown-select .MuiInput-root {
    min-width: 100px; }
    .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-select {
      color: #252529 !important;
      font-weight: 400;
      font-size: 14px; }
      .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-select:focus {
        background-color: transparent; }
    .main-container-appointments .dropdown-select .MuiInput-root .MuiSelect-icon {
      color: #252529;
      right: -5px; }
    .main-container-appointments .dropdown-select .MuiInput-root:before {
      border-bottom-color: #252529; }
    .main-container-appointments .dropdown-select .MuiInput-root:after {
      border-bottom: 1px solid #133590; }
    .main-container-appointments .dropdown-select .MuiInput-root:hover:not(.Mui-disabled):before {
      border-bottom: 1px solid #00c2ff; }
    .main-container-appointments .dropdown-select .MuiInput-root:hover .MuiSelect-icon,
    .main-container-appointments .dropdown-select .MuiInput-root:hover .MuiSelect-select {
      color: #00c2ff !important; }

.MuiPopover-paper {
  box-shadow: 0 2px 5px 1px rgba(69, 65, 78, 0.08), 0 2px 5px 1px rgba(69, 65, 78, 0.04) !important; }
  .MuiPopover-paper .MuiMenu-list .MuiListItem-button {
    font-weight: 400 !important;
    font-size: 14px !important;
    color: #252529 !important; }
    .MuiPopover-paper .MuiMenu-list .MuiListItem-button:hover {
      background-color: #00c2ff;
      color: #fff !important; }
  .MuiPopover-paper .MuiMenu-list .Mui-selected {
    background-color: #00c2ff;
    color: #fff !important; }

.not-found-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }
  .not-found-container .inner-container {
    color: #25345c;
    font-family: 'Open Sans', sans-serif;
    margin-top: 100px;
    max-width: 340px;
    text-align: center; }
    .not-found-container .inner-container .nf-img {
      width: 140px; }
    .not-found-container .inner-container .nf-head {
      font-weight: 600;
      font-size: 26px;
      margin-bottom: 0; }
    .not-found-container .inner-container .nf-btn {
      border: none;
      background: #25345c;
      color: #fff;
      padding: 15px 40px;
      margin-top: 20px;
      border-radius: 5px;
      font-family: 'Open Sans', sans-serif;
      outline: none; }

